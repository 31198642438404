import { ApiOutlined, ExclamationCircleOutlined, TableOutlined } from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { COLLECT_MENU, IMPORTS_MENU, ROOT } from 'src/navigation/routes'
import { usePermissions } from 'src/navigation/_hooks'
import { useCampaignsMenu } from 'src/navigation/_hooks/useCampaignsMenu'
import { useImportsMenu } from 'src/navigation/_hooks/useImportsMenu'
import type { ItemType } from 'antd/es/menu/interface'
import { TextToolIcon } from 'src/icons'
import { Tooltip } from 'antd'
import { useListMappingTablesQuery } from 'src/generated/graphql/types'

export const useCollectMenu = (): ItemType => {
  const { t } = useTypedTranslation()
  const {
    canReadAnalyses,
    canReadUpload,
    permissionsLoading,
    canUploadFile,
    canManageManualEntries,
    canUpdateManualEntries
  } = usePermissions()
  const campaignMenu = useCampaignsMenu()
  const importsMenu = useImportsMenu()
  const { canManageCampaigns } = usePermissions()
  const isConnectV1Enabled = useOverridableFlag(UnleashFlag.ConnectV1)
  const isConnectV2Enabled = useOverridableFlag(UnleashFlag.ConnectV2)
  const isManualEntriesEnabled = useOverridableFlag(UnleashFlag.ManualEntries)
  const isReleaseCampaignsEnabled = useOverridableFlag(UnleashFlag.ReleaseCampaigns)
  const { data } = useListMappingTablesQuery({
    variables: { pagination: { limit: 1 }, filter: { withUnpublishedChanges: true } }
  })
  const isMappingWarning = data?.listMappingTables.totalCount !== undefined && data.listMappingTables.totalCount > 0

  if (permissionsLoading) return null
  if (!canReadAnalyses && !canReadUpload && !canUploadFile) return null

  const children: ItemType[] = []

  if (campaignMenu) children.push(campaignMenu)
  if (importsMenu) children.push(importsMenu)

  if ((isReleaseCampaignsEnabled && canManageCampaigns) || isConnectV2Enabled) {
    children.push({
      key: `${IMPORTS_MENU} ${ROOT}/mappings/*`,
      icon: <TableOutlined />,
      label: (
        <Tooltip title={isMappingWarning ? t('mappings.menu_warning') : ''}>
          <OrganizationLink to="/mappings">
            {t('menu.mappings')}
            {isMappingWarning && <ExclamationCircleOutlined className="text-warning-foreground ml-2" />}
          </OrganizationLink>
        </Tooltip>
      )
    })
  }

  if (isManualEntriesEnabled && (canUpdateManualEntries || canManageManualEntries)) {
    children.push({
      key: `${ROOT}/manualEntries`,
      icon: <TextToolIcon />,
      label: <OrganizationLink to="/manualEntries">{`${t('menu.manual_entries')}`}</OrganizationLink>
    })
  }

  if (isConnectV1Enabled && canReadUpload) {
    children.push({
      key: `${ROOT}/connect/*`,
      icon: <ApiOutlined />,
      label: <OrganizationLink to="/connect">{t('menu.connect')}</OrganizationLink>
    })
  }

  if (children.length === 0) return null
  return {
    key: COLLECT_MENU,
    type: 'group',
    label: <>{t('menu.collect')}</>,
    children
  }
}
