import { OrganizationButton } from 'src/navigation/legacy/OrganizationButton'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { useOrganizationConfig } from 'src/provider'
import { useImpersonateEmail } from 'src/common/hooks/useImpersonateEmail'
import { UnImpersonateButton } from 'src/navigation/legacy/UnImpersonateButton'

const Profile = () => {
  const { currentUser, organization } = useOrganizationConfig()
  const { isImpersonateUser } = useImpersonateEmail()

  return (
    <div className="flex space-x-2 h-full">
      <div className="flex flex-col overflow-hidden justify-between py-1 gap-1" style={{ lineHeight: '18px' }}>
        <div className="flex flex-row gap-2">
          <div className="truncate w-full font-medium cursor-pointer relative">
            <OrganizationLink to={`/settings/team/users/${currentUser.authUser.id}`}>
              {currentUser.fullName}
            </OrganizationLink>
          </div>
          {isImpersonateUser && <UnImpersonateButton />}
        </div>
        <div className="truncate overflow-y-visible w-full font-light cursor-auto relative">
          <OrganizationButton organizationName={organization.name} compactMode />
        </div>
      </div>
    </div>
  )
}

export default Profile
