import { PieChartOutlined } from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { ANALYZE_MENU } from 'src/navigation/routes'
import { usePermissions } from 'src/navigation/_hooks/usePermissions'

export function useLCAMenu() {
  const isLCAModuleEnabled = useOverridableFlag(UnleashFlag.ModuleLCAEnabled)

  const { canManageLCAs } = usePermissions()
  const { t } = useTypedTranslation()

  if (!canManageLCAs || !isLCAModuleEnabled) {
    return null
  }

  return {
    key: `${ANALYZE_MENU}/lcaTemplates`,
    icon: <PieChartOutlined />,
    label: <OrganizationLink to="/lcaTemplates">{t('menu.lca')}</OrganizationLink>
  }
}
