import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { REPORTING_MENU, ROOT } from 'src/navigation/routes'
import type { ItemType } from 'antd/es/menu/interface'
import { PositionIcon } from 'src/icons'
import { usePermissions } from 'src/navigation/_hooks/usePermissions'

export function useReportingMenu(): ItemType {
  const isModuleReportingEnabled = useOverridableFlag(UnleashFlag.ModuleReporting)
  const { canReadAndEditReport } = usePermissions()

  const { t } = useTypedTranslation()

  if (!isModuleReportingEnabled || !canReadAndEditReport) return null

  return {
    key: REPORTING_MENU,
    type: 'group',
    label: t('menu.reporting'),
    children: [
      {
        key: `${REPORTING_MENU} ${ROOT}/reporting/*`,
        icon: <PositionIcon />,
        label: <OrganizationLink to="/reporting">{t('menu.reporting_list')}</OrganizationLink>
      }
    ]
  }
}
