import { usePermissions } from 'src/navigation/_hooks'
import 'src/navigation/NavContainer.css'
import NavMenu from 'src/navigation/NavMenu'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import LegacyNavMenu from 'src/navigation/legacy/LegacyNavMenu'

export default function NavContainer() {
  const { permissionsLoading, permissionsError, permissionsData } = usePermissions()
  const isNewBrandingEnabled = useOverridableFlag(UnleashFlag.NewBranding)

  if (permissionsLoading) {
    return null
  }

  if (permissionsError || !permissionsData) {
    console.error(permissionsError)
    return null
  }

  return isNewBrandingEnabled ? <NavMenu /> : <LegacyNavMenu />
}
