export interface AuthUserInfoInterface {
  id: string
  email: string
  firstName?: string | null
  lastName?: string | null
  pictureUrl?: string | null
  isDeleted: boolean
}

export default class AuthUserInfo {
  id: string
  email: string
  firstName: string | null
  lastName: string | null
  pictureUrl: string | null
  isDeleted: boolean

  constructor(authUser: AuthUserInfoInterface) {
    if (!authUser.id) {
      throw new Error('AuthUser id is required')
    }

    if (!authUser.email) {
      throw new Error('AuthUser email is required')
    }

    this.id = authUser.id
    this.email = authUser.email
    this.firstName = authUser.firstName ?? null
    this.lastName = authUser.lastName ?? null
    this.pictureUrl = authUser.pictureUrl || null
    this.isDeleted = authUser.isDeleted
  }

  get initials(): string {
    if (this.firstName && this.lastName) {
      return (this.firstName.charAt(0) + this.lastName.charAt(0)).toUpperCase()
    }
    return this.email.charAt(0).toUpperCase()
  }

  get fullName(): string | null {
    if (this.firstName == null || this.lastName == null) {
      return null
    }
    return `${this.firstName} ${this.lastName}`
  }
}
