import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { Avatar, Dropdown, type GetProp, Layout, Menu, type MenuProps } from 'antd'
import isEmpty from 'lodash/fp/isEmpty'
import last from 'lodash/fp/last'
import { Outlet } from 'react-router'
import Profile from 'src/navigation/Profile'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import {
  useAnalyzeMenu,
  useCollectMenu,
  useMyTasksMenu,
  useNavigationState,
  useReduceMenu,
  useSettingsMenu,
  useReportingMenu,
  useAdminMenu,
  useAddonsMenu,
  useUserMenu
} from 'src/navigation/_hooks'
import { USER_ROUTE } from 'src/navigation/routes'
import { useTheme } from 'src/app/theme/useTheme'
import { useBoolean, useResizeObserver } from 'usehooks-ts'
import { useRef, useState } from 'react'
import type { ItemType } from 'antd/es/menu/interface'
import { AgendaBookmarkIcon, RightIcon, MinimizeIcon } from 'src/icons'
import classNames from 'classnames'
import { useOrganizationConfig } from 'src/provider'
import AuthUserModel from 'src/models/AuthUser'
import { useHelpLink } from 'src/navigation/_hooks/useHelpLink'

const HELP_KEY = 'help'

export default function NavMenu() {
  const { t } = useTypedTranslation()
  const { value: isCollapsed, toggle: toggleIsCollapsed } = useBoolean(false)
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const isReduceModuleEnabled = useOverridableFlag(UnleashFlag.ModuleReduceEnabled)
  const { currentUser } = useOrganizationConfig()
  const openHelp = useHelpLink('https://help.traace.co')
  const authUser = new AuthUserModel(currentUser.authUser)
  const myTasksMenu = useMyTasksMenu()
  const collectMenu = useCollectMenu()
  const reduceMenu = useReduceMenu({ isReduceModuleEnabled: isReduceModuleEnabled })
  const settingsMenu = useSettingsMenu()
  const adminMenu = useAdminMenu()
  const analyzeMenu = useAnalyzeMenu()
  const reportingMenu = useReportingMenu()
  const addonsMenu = useAddonsMenu()
  const { images } = useTheme()
  const userDropdownItems = useUserMenu({ withProfilePage: true })
  const fixedBottomRef = useRef<HTMLDivElement>(null)
  const fixedTopRef = useRef<HTMLDivElement>(null)
  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  const { height: fixedBottomHeight } = useResizeObserver({ ref: fixedBottomRef })
  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  const { height: fixedTopHeight } = useResizeObserver({ ref: fixedTopRef })

  const upMenuItems: ItemType[] = [myTasksMenu]

  if (collectMenu) upMenuItems.push(collectMenu)
  if (analyzeMenu) upMenuItems.push(analyzeMenu)
  if (reduceMenu) upMenuItems.push(reduceMenu)
  if (reportingMenu) upMenuItems.push(reportingMenu)
  if (addonsMenu) upMenuItems.push(addonsMenu)

  if (isCollapsed) {
    for (const menu of upMenuItems) {
      if (menu && menu.type === 'group') menu.label = ''
    }
  }

  const downMenuItems: GetProp<typeof Menu, 'items'> = [
    {
      key: 'help',
      icon: <AgendaBookmarkIcon />,
      label: <div>{t('menu.docs')}</div>,
      onClick: openHelp
    },
    settingsMenu,
    adminMenu
  ]

  const { selectedKeys, setSelectedKeys } = useNavigationState({ upMenuItems, downMenuItems, setOpenKeys })

  const onOpenChange = (keys: string[]) => {
    const openedKeys = isEmpty(keys) ? [] : [last(keys) as string]
    setOpenKeys(openedKeys)
  }
  const onMenuClick: GetProp<MenuProps, 'onClick'> = item => {
    if (item.key !== HELP_KEY) {
      setOpenKeys(item.keyPath)
      setSelectedKeys([item.key])
    }
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Sider
        breakpoint="lg"
        collapsedWidth={52}
        collapsible
        collapsed={isCollapsed}
        onCollapse={toggleIsCollapsed}
        width={250}
        style={{ width: 'inherit' }}
        className="z-10 min-h-[100vh]"
        trigger={null}
      >
        <div
          className="flex flex-col fixed top-0 bottom-0 overflow-y-auto overflow-x-hidden bg-sidebar-background border-r border-border"
          style={{ width: 'inherit' }}
        >
          <div ref={fixedTopRef} className="fixed top-0 bg-sidebar-background z-20">
            {isCollapsed ? (
              <div
                className="group flex flex-col gap-2 justify-center items-center h-8 px-3 pt-5 pb-7 cursor-pointer w-[51px]"
                onClick={toggleIsCollapsed}
              >
                <img src={images.compactLogoUrl} className="m-auto group-hover:hidden" alt="" width="20px" />
                <MinimizeIcon className="hidden group-hover:block absolute m-auto text-sidebar-foreground" />
              </div>
            ) : (
              <div className="flex justify-between items-center px-3 py-5 w-[249px]">
                <a href="/" className="">
                  <img src={images.logoUrl} className="m-auto" alt="" width="112px" height="20px" />
                </a>
                <MinimizeIcon className="mr-1 text-sidebar-foreground cursor-pointer" onClick={toggleIsCollapsed} />
              </div>
            )}
          </div>
          <div className="flex flex-col h-full justify-between">
            <div style={{ paddingBottom: `${fixedBottomHeight}px`, paddingTop: fixedTopHeight }}>
              <Menu
                mode="inline"
                selectable={false}
                className="border-0 py-3"
                items={upMenuItems}
                openKeys={openKeys}
                selectedKeys={selectedKeys}
                onOpenChange={onOpenChange}
                onClick={onMenuClick}
                expandIcon={({ isOpen }) =>
                  isCollapsed ? null : <RightIcon className={classNames('transition', { 'rotate-z-90': isOpen })} />
                }
              />
            </div>
            <div
              ref={fixedBottomRef}
              className={classNames('fixed bottom-0', {
                'w-[249px]': !isCollapsed,
                'w-[51px]': isCollapsed
              })}
            >
              <Menu
                mode="inline"
                selectable={false}
                className={classNames('border-0 border-t border-border', { 'py-3': !isCollapsed, 'py-1': isCollapsed })}
                items={downMenuItems}
                openKeys={openKeys}
                selectedKeys={selectedKeys}
                onOpenChange={onOpenChange}
                onClick={onMenuClick}
                expandIcon={({ isOpen }) =>
                  isCollapsed ? null : <RightIcon className={classNames('transition', { 'rotate-z-90': isOpen })} />
                }
              />
              <Menu
                mode="inline"
                selectable={false}
                className={classNames('border-0 border-t border-border', { 'py-3': !isCollapsed, 'py-1': isCollapsed })}
                items={[
                  {
                    key: `${USER_ROUTE}/*`,
                    icon: isCollapsed ? (
                      <Dropdown trigger={['click']} menu={{ items: userDropdownItems }}>
                        <Avatar size="small" src={authUser.pictureUrl} className="-ml-1 -mt-0.5">
                          {authUser.initials}
                        </Avatar>
                      </Dropdown>
                    ) : (
                      <Avatar size="small" src={authUser.pictureUrl} className="-ml-1 -mt-0.5">
                        {authUser.initials}
                      </Avatar>
                    ),
                    label: isCollapsed ? currentUser.fullName : <Profile />
                  }
                ]}
                openKeys={openKeys}
                selectedKeys={selectedKeys}
                onOpenChange={onOpenChange}
                onClick={onMenuClick}
              />
            </div>
          </div>
        </div>
      </Layout.Sider>
      <Layout>
        <Layout.Content>
          <div className="w-full">
            <Outlet />
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  )
}
