import { Theme, type ThemeColorsType, type ThemeType } from 'src/app/theme/type'
import { getDesignColors } from 'src/app/theme/common'
import { newAppTheme } from 'src/app/theme/theme'

const colors: ThemeColorsType = {
  primary: '#062A47',
  brand: '#021F35',
  sidebarBackground: '#FAFAF8',
  sidebarForeground: '#575756',
  sidebarAccent: '#E5E5E4',
  sidebarAccentForeground: '#062A47',
  icon1: '#5EBDF9',
  active: '#E5E5E4',
  warning: '#FDF5E6',
  warningForeground: '#D98A04',
  error: '#FEF2F2',
  errorForeground: '#781B1A',
  success: '#F0FDEE',
  successForeground: '#26842D',
  info: '#EAF4F8',
  infoForeground: '#062A47',
  background: '#FFFFFF',
  foreground: '#1A1A19',
  foregroundTitle: '#021F35',
  border: '#E5E5E4',
  mutedForeground: '#80807E',
  muted: '#FAFAF8',
  secondary: '#FAFAF8',
  secondaryForeground: '#1A1A19',
  accent: '#FAFAF8',
  linkForeground: '#1A1A19',
  chart1: '#5EBDF9',

  // TODO to remove
  backgroundLight: '#fff',
  primaryOnDark: '#F0FDEE', // TODO replace with dark success? how, this is not a token for light theme?
  errorOnDark: '#DC2626', // TODO replace with dark error? how, this is not a token for light theme?
  darkgreenDarker: '#062A47', // TODO replace with primary
  darkgreenBase: '#021F35', // TODO replace with brand
  darkgreenLighter1: '#021F35', // TODO replace with brand
  darkgreenLighter2: '#021F35', // TODO replace with brand
  lightgreenBase: '#021F35', // TODO replace with brand
  lightgreenLighter1: '#5EBDF9', // TODO replace with chart1
  lightgreenLighter2: '#F0FDEE', // TODO replace with success bg color (own token or fg token + opacity)
  purpleDarker: '#120f23',
  purpleBase: '#2b225a',
  purpleLighter1: '#484073',
  purpleLighter2: '#78719e',
  purpleLighter3: '#a29dbc',
  purpleLighter4: '#f1f0f3',
  sandDarker: '#ef9e3d',
  sandBase: '#ffcf97',
  sandLighter1: '#ffe5c6',
  sandLighter2: '#fff4e6',
  greengrey80: '#101414',
  greengrey70: '#191f1d',
  greengrey60: '#212927',
  greengrey50: '#293331',
  greengrey40: '#5f6664',
  greengrey30: '#949998',
  greengrey20: '#c9cccb',
  greengrey10: '#dfe0e0',
  greengrey8: '#eeefef',
  greengrey5: '#f5f5f5',
  greengrey2: '#fbfbfb',
  white: '#fff',
  ...getDesignColors()
}

export const lightTheme: ThemeType = {
  name: Theme.Light,
  colors,
  images: {
    logoUrl: '/images/logo_new.svg',
    compactLogoUrl: '/images/logo_new_mini.svg'
  },
  config: newAppTheme(colors)
}
