import { useParams } from 'react-router'
import { useCurrentOrganizationEmissionsItemsCategoriesQuery } from 'src/generated/graphql/types'
import { GHG_INDUCED_EMISSIONS } from 'src/common/hooks/useMetrics'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'

type CategoriesProps = {
  metricId?: string
}

export function useCategories({ metricId = GHG_INDUCED_EMISSIONS }: CategoriesProps = {}) {
  const { organizationId } = useParams<{ organizationId: string }>()
  const isMetricCategoryTreeEnabled = useOverridableFlag(UnleashFlag.ReleaseMetricCategoryTree)
  const { data, ...rest } = useCurrentOrganizationEmissionsItemsCategoriesQuery({
    variables: {
      organizationEmissionsItemsCategoriesInput: {
        organizationId: organizationId!,
        metricId: isMetricCategoryTreeEnabled ? metricId : GHG_INDUCED_EMISSIONS
      }
    },
    skip: !organizationId
  })

  return {
    data,
    getCategory: (categoryId?: string) =>
      data?.organizationEmissionsItemsCategories.find(category => category.id === categoryId),
    ...rest
  }
}
