import React, { useEffect } from 'react'
import { type TreeSelectProps } from 'antd'
import { MetricGroup, useListMetricsQuery } from 'src/generated/graphql/types'
import { useTypedTranslation } from 'src/common/utils'
import debounce from 'lodash/fp/debounce'
import groupBy from 'lodash/fp/groupBy'
import type { I18nKey } from 'src/generated/i18n-types'
import isEmpty from 'lodash/fp/isEmpty'
import type { Metric } from 'src/type'
import { MetricLabel } from 'src/common/designSystem/labels/MetricLabel'
import type { SafeKey } from 'antd/es/table/interface'
import { notNil } from 'src/common/utils/notNil'
import { TreeSelect } from 'src/common/designSystem/select/TreeSelect'

type MetricsSelectProps = Readonly<
  {
    readOnly?: boolean
    includeArchived?: boolean
  } & TreeSelectProps<string>
>

const orderedMetricGroup = [MetricGroup.Environment, MetricGroup.Social, MetricGroup.Governance, MetricGroup.Other]

export function MetricSelect({ readOnly, includeArchived = false, ...props }: MetricsSelectProps) {
  const { t } = useTypedTranslation()
  const [searchValue, setSearchValue] = React.useState<string | undefined>(undefined)
  const [treeOptions, setTreeOptions] = React.useState<TreeSelectProps['treeData'] | undefined>([])
  const [expandedKeys, setExpandedKeys] = React.useState<SafeKey[]>([])
  const { data, loading, error } = useListMetricsQuery({
    variables: { offset: 0, limit: 1000, labelLike: searchValue, includeArchived }
  })

  useEffect(() => {
    if (!loading) {
      setTreeOptions(
        orderedMetricGroup
          .map(key => {
            const metrics = groupedMetrics[key] ?? []
            return {
              title: <span className="font-bold text-greengrey-80">{t(`metrics.group.${key}` as I18nKey)}</span>,
              key: key,
              value: key,
              selectable: false,
              children: metrics.map(metric => ({
                key: metric.id,
                title: <MetricLabel metric={metric} />,
                value: metric.id,
                isLeaf: true
              }))
            }
          })
          .filter(option => !isEmpty(option.children))
      )
    }
  }, [data, loading])

  const groupedMetrics = groupBy<Metric>('metricGroup')(data?.listMetrics.results)
  const selectedMetric = data?.listMetrics.results.find(metric => metric.id === props.value)

  useEffect(() => {
    if (!searchValue && selectedMetric) {
      setExpandedKeys([selectedMetric.metricGroup])
    } else {
      setExpandedKeys(
        treeOptions!
          .filter(option => !isEmpty(option.children))
          .map(option => option.value)
          .filter(notNil)
      )
    }
  }, [searchValue, treeOptions, selectedMetric])

  return (
    <TreeSelect
      error={error}
      loading={loading}
      aria-readonly={readOnly}
      disabled={readOnly}
      showSearch
      treeData={treeOptions}
      treeExpandedKeys={expandedKeys}
      popupMatchSelectWidth={false}
      filterTreeNode={false}
      onTreeExpand={keys => {
        setExpandedKeys(keys)
      }}
      onClear={() => setSearchValue(undefined)}
      onSearch={debounce(200)((value: string) => setSearchValue(value))}
      placeholder={props.placeholder ?? t('common.impact_factor.placeholder')}
      {...props}
    />
  )
}
