import { message } from 'antd'
import isNil from 'lodash/fp/isNil'
import { type ReactNode, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useCurrentUserConfigQuery } from 'src/generated/graphql/types'
import { UserInfos } from 'src/models/UserInfos'
import ActionPlanForecastChartProvider from 'src/provider/ActionPlanForecastChartProvider'
import { useTypedTranslation } from '../common/utils'
import CenteredLoader from '../common/utils/CenteredLoader'
import { PreferredLanguageLocalStorageKey } from '../common/utils/PreferredLanguageDetector'
import { OrganizationConfigContext } from 'src/provider/ConfigContext'

type OrganizationConfigProviderProps = {
  readonly children: ReactNode
}

export function ConfigProvider({ children }: OrganizationConfigProviderProps) {
  const { t } = useTypedTranslation()
  const navigate = useNavigate()
  const { loading, error, data } = useCurrentUserConfigQuery()

  useEffect(() => {
    if (error) {
      for (const graphQLError of error.graphQLErrors) {
        if (graphQLError.extensions?.code === 'FORBIDDEN') {
          console.error(t('common.user_does_not_belong_to_organization'), error)
          message.error(t('common.user_does_not_belong_to_organization'), 3.2)
          setTimeout(() => navigate('/'), 3000)
          return
        }
      }
      console.error(error)
    }
  }, [error])

  const contextValue = useMemo(() => {
    if (!data) {
      return undefined
    }

    const authUser = data.currentUser.authUser
    const organization = data.currentUser.organization

    if (authUser.language !== localStorage.getItem(PreferredLanguageLocalStorageKey)) {
      if (authUser.language) {
        localStorage.setItem(PreferredLanguageLocalStorageKey, authUser.language)
      } else {
        localStorage.removeItem(PreferredLanguageLocalStorageKey)
        localStorage.removeItem('i18nextLng') // Remove cached value
      }
      window.location.reload()
      return null
    }

    return {
      isTraaceAdmin: !isNil(authUser.adminRole),
      currentUser: new UserInfos(data.currentUser),
      organization,
      currentUserDefaultPerimeterScope: {
        contributingEntityIds: data.currentUser.defaultContributingEntitiesFilter,
        organizationNodeIds: data.currentUser.defaultOrganizationNodesFilter
      }
    }
  }, [data])

  if (loading) {
    return <CenteredLoader />
  }

  if (error || !contextValue) {
    return null
  }

  return (
    <OrganizationConfigContext.Provider value={contextValue}>
      <ActionPlanForecastChartProvider>{children}</ActionPlanForecastChartProvider>
    </OrganizationConfigContext.Provider>
  )
}
