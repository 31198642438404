import React, { type PropsWithChildren, useMemo, useState } from 'react'
import { useTypedTranslation } from 'src/common/utils'
import {
  type QueryDownloadEmissionsReportArgs,
  ReportStandard,
  useDownloadEmissionsReportLazyQuery,
  useEmissionsExportCompletionSubscription
} from 'src/generated/graphql/types'

import { useMessageAPI } from 'src/provider/useMessageAPI'
import { EmissionsExportDownloadContext } from 'src/provider/EmissionsExportDownloadContext'
import { ApolloError } from '@apollo/client'

export type OngoingExportRequest = {
  requestId: string
  reportStandard: ReportStandard
}

/**
 * We use a provider to keep track of the ongoing export requests.
 * The Apollo subscription is always active when the user is logged in an organization.
 */
export function EmissionsExportDownloadProvider({ children }: PropsWithChildren) {
  const { t } = useTypedTranslation()

  const [ongoingExportRequests, setOngoingExportRequests] = useState<OngoingExportRequest[]>([])

  const messageAPI = useMessageAPI()

  const [downloadEmissionsReport] = useDownloadEmissionsReportLazyQuery()

  function translateStandard(reportStandard: ReportStandard) {
    switch (reportStandard) {
      case ReportStandard.Beges:
        return t('bi.export_menu_item_beges')
      case ReportStandard.BilanCarboneV8:
        return t('bi.export_menu_item_bilan_carbone_v8')
      case ReportStandard.BilanCarboneV9:
        return t('bi.export_menu_item_bilan_carbone_v9')
      case ReportStandard.IsoTr_14069:
        return t('bi.export_menu_item_iso_tr_14069')
      case ReportStandard.GhgProtocol:
        return t('bi.export_menu_item_ghg_protocol')
      default:
        return t('bi.export_menu_item_traace')
    }
  }

  useEmissionsExportCompletionSubscription({
    onData: ({ data }) => {
      if (data.data?.emissionsExportCompletion) {
        const exportRequest = ongoingExportRequests.find(
          ongoingExport => ongoingExport.requestId === data.data?.emissionsExportCompletion.frontendRequestId
        )

        if (exportRequest) {
          setOngoingExportRequests(ongoingExportRequests.filter(ongoingExport => ongoingExport !== exportRequest))

          if (data.data.emissionsExportCompletion.downloadUrl) {
            messageAPI.info(
              t('bi.export_ready_message', { exportStandard: translateStandard(exportRequest.reportStandard) })
            )

            window.open(data.data.emissionsExportCompletion.downloadUrl, '_blank')
          } else {
            messageAPI.error(data.data.emissionsExportCompletion.errorMessage)
          }
        }
      } else if (data.error) {
        messageAPI.error(data.error.message)
        setOngoingExportRequests([])
      }
    }
  })

  const value = useMemo(
    () => ({
      isAnOngoingExportRequest: ongoingExportRequests.length > 0,
      ongoingExportRequests,
      sendEmissionsExportRequest: async (
        input: Omit<QueryDownloadEmissionsReportArgs['exportDashboardInput'], 'frontendRequestId'>
      ) => {
        const exportRequestId = Math.random().toString(36).slice(2, 7)

        const exportRequest = {
          requestId: exportRequestId,
          reportStandard: input.reportStandard
        }

        setOngoingExportRequests(ongoingExportRequests.concat(exportRequest))

        try {
          await downloadEmissionsReport({
            variables: {
              exportDashboardInput: {
                ...input,
                frontendRequestId: exportRequestId
              }
            }
          })
          messageAPI.info(t('bi.export_started_message', { exportStandard: translateStandard(input.reportStandard) }))
        } catch (error) {
          if (error instanceof ApolloError) {
            setOngoingExportRequests(ongoingExportRequests.filter(ongoingExport => ongoingExport !== exportRequest))
            messageAPI.error(error.message, 5)
          }
        }
      }
    }),
    [ongoingExportRequests, downloadEmissionsReport]
  )

  return <EmissionsExportDownloadContext.Provider value={value}>{children}</EmissionsExportDownloadContext.Provider>
}
