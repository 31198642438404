import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { usePermissions } from 'src/navigation/_hooks'

export function useDataManagementTabsPermissions(): {
  displayDataManagementModule: boolean
  displayTab: {
    categories: boolean
    indicators: boolean
    metrics: boolean
    impactFactors: boolean
    tags: boolean
    dataOrigins: boolean
    dataSharing: boolean
  }
} {
  const { canReadImpactFactor, canManageDataPointTags, canManageOrganizationSettings, canReadCategory } =
    usePermissions()

  const metricsReleased = useOverridableFlag(UnleashFlag.ReleaseMetrics)
  const dataSharingEnabled = useOverridableFlag(UnleashFlag.FeatureDataSharingEnabled)
  const isMetricCategoryTreeEnabled = useOverridableFlag(UnleashFlag.ReleaseMetricCategoryTree)

  const allowedTabs = {
    categories: canReadCategory && !isMetricCategoryTreeEnabled,
    indicators: canManageOrganizationSettings,
    metrics: canManageOrganizationSettings && metricsReleased,
    impactFactors: canReadImpactFactor && !isMetricCategoryTreeEnabled,
    tags: canManageDataPointTags,
    dataOrigins: canManageOrganizationSettings,
    dataSharing: dataSharingEnabled && canManageOrganizationSettings
  }

  return {
    displayDataManagementModule: Object.values(allowedTabs).some(Boolean),
    displayTab: allowedTabs
  }
}
