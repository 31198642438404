import { type NavigateOptions as BaseNavigateOptions, type To, useNavigate, useParams } from 'react-router'
import isString from 'lodash/fp/isString'
import { generateOrganizationLink } from 'src/navigation/GenerateOrganizationLink'

type NavigateOptions = BaseNavigateOptions & {
  openInNewTab?: boolean
}

export function useOrganizationNavigate(prefix = '') {
  const navigate = useNavigate()
  const { organizationId } = useParams<'organizationId'>()
  return function (to: To | string, options?: NavigateOptions): void {
    if (isString(to)) {
      const path = generateOrganizationLink(prefix + to, organizationId)
      if (options?.openInNewTab) {
        window.open(path, '_blank', 'rel=noopener noreferrer')
      } else {
        navigate(path, options)
      }
    } else {
      const pathname = to.pathname ? generateOrganizationLink(prefix + to.pathname, organizationId) : undefined
      navigate({ ...to, pathname }, options)
    }
  }
}
