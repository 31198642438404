import { Fragment, type ReactNode, useRef } from 'react'
import { useHover } from 'usehooks-ts'
import { Popover } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import find from 'lodash/fp/find'
import isEmpty from 'lodash/isEmpty'
import { buildCategoryArrayForPath, useTypedTranslation } from 'src/common/utils'
import {
  type EmissionsItemsCategory,
  useCurrentOrganizationEmissionsItemsCategories
} from 'src/modules/settings/dataManagement/categories/_hooks/useOrganizationEmissionsItemsCategories'
import { CategoryDisplay } from 'src/common/designSystem/labels/category/CategoryDisplay'
import { CategoryLabel } from 'src/common/designSystem/labels/category/CategoryLabel'
import { QuestionTag } from '../QuestionTag'
import { TranslatedValue } from 'src/common/designSystem/TranslatedValue'

type CategoryPopoverProps = {
  readonly category: CategoryLabel
  readonly children: ReactNode
  readonly visible: boolean
}

export function CategoryPopover({ category, children, visible }: CategoryPopoverProps) {
  const { t, getTranslatedValue } = useTypedTranslation()
  const { data } = useCurrentOrganizationEmissionsItemsCategories()
  const currentCategory = find<EmissionsItemsCategory>({
    id: category.id
  })(data?.emissionItemsCategories ?? [])
  const categoryPathArray = buildCategoryArrayForPath(
    currentCategory ? [currentCategory] : [],
    data?.emissionItemsCategories ?? []
  )
  const hoverRef = useRef(null)
  // @ts-expect-error React 19 type compatibility, nullable ref can be ignored.
  const isHover = useHover(hoverRef)

  if (!visible) return children

  return (
    <Popover
      styles={{ root: { width: 'auto', maxWidth: '80rem' } }}
      open={isHover}
      content={
        <div className="flex flex-col gap-1">
          <div className="flex gap-3 flex-wrap items-center">
            {categoryPathArray.map((pathCategory, index) => {
              return (
                <Fragment key={getTranslatedValue(pathCategory.name).value ?? ''}>
                  {index !== 0 && '/'}
                  <div className="flex gap-1 items-center">
                    <CategoryDisplay category={pathCategory} size="normal" />
                    <TranslatedValue fields={pathCategory.name} />
                  </div>
                  {pathCategory.carbonReportingCategory && (
                    <QuestionTag icon={false}>
                      {t('categories.scope', { scope: pathCategory.carbonReportingCategory.scope })}
                    </QuestionTag>
                  )}
                </Fragment>
              )
            })}
          </div>
          {!isEmpty(category.description) && (
            <div className="flex gap-2 text-[14px] text-[#949998] font-light">
              <InfoCircleOutlined />
              <TranslatedValue className="whitespace-pre-line" fields={category.description} />
            </div>
          )}
        </div>
      }
    >
      <div ref={hoverRef}>{children}</div>
    </Popover>
  )
}
