import { FileDoneOutlined } from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { REPORTING_MENU } from 'src/navigation/routes'
import { usePermissions } from 'src/navigation/_hooks'

export function useReportingMenu() {
  const isModuleReportingEnabled = useOverridableFlag(UnleashFlag.ModuleReporting)
  const { canReadAndEditReport } = usePermissions()

  const { t } = useTypedTranslation()

  if (!isModuleReportingEnabled || !canReadAndEditReport) return null

  return {
    key: REPORTING_MENU,
    icon: <FileDoneOutlined />,
    label: <OrganizationLink to="/reporting">{t('menu.reporting')}</OrganizationLink>
  }
}
