import React from 'react'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { useConvertQuillToLexical } from './hooks'
import { HeadingNode } from '@lexical/rich-text'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import classNames from 'classnames'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { EditorToolbarPlugin, ResetStyleOnEnterPlugin } from 'src/common/editor/plugins'
import { ImageNodeDecorator } from 'src/common/editor/nodes/image'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import {
  $getRoot,
  $isDecoratorNode,
  $isElementNode,
  $isTextNode,
  type EditorState,
  type ElementNode,
  type LexicalEditor
} from 'lexical'
import isUndefined from 'lodash/fp/isUndefined'
import urlRegexSafe from 'url-regex-safe'
import isString from 'lodash/fp/isString'
import isEmpty from 'lodash/isEmpty'

export type TraaceEditorProps = Readonly<{
  value?: string | object | null
  disabled?: boolean
  onChange?: (editorState: string) => void
  className?: string
}>

function getJsonData(data: string | object | null | undefined) {
  if (isString(data)) {
    try {
      return JSON.parse(data)
    } catch (e) {
      console.error(e)
      return {}
    }
  }
  return data
}

function isLexicalJson(data: any) {
  return !isUndefined(data?.['root'])
}

function validateUrl(url: string): boolean {
  return urlRegexSafe({ exact: true }).test(url)
}

const theme = {
  paragraph: 'traace-editor-paragraph',
  link: 'traace-editor-link',
  text: {
    bold: 'traace-editor-textBold',
    code: 'traace-editor-textCode',
    italic: 'traace-editor-textItalic',
    strikethrough: 'traace-editor-textStrikethrough',
    underline: 'traace-editor-textUnderline',
    underlineStrikethrough: 'traace-editor-textUnderlineStrikethrough'
  }
}

// Founded here https://stackoverflow.com/a/78451709
function $isWhitespace(node: ElementNode): boolean {
  for (const child of node.getChildren()) {
    if (
      ($isElementNode(child) && !$isWhitespace(child)) ||
      ($isTextNode(child) && child.getTextContent().trim() !== '') ||
      $isDecoratorNode(child) // decorator nodes are arbitrary
    ) {
      return false
    }
  }
  return true
}

function $isEmpty(editorState: EditorState) {
  return editorState.read(() => {
    const root = $getRoot()
    const child = root.getFirstChild()

    if (child == null || ($isElementNode(child) && child.isEmpty() && root.getChildrenSize() === 1)) {
      return true
    }

    return $isWhitespace(root)
  })
}

export function TraaceEditor({ value, disabled = false, onChange = () => null, className }: TraaceEditorProps) {
  const json = isEmpty(value) ? {} : getJsonData(value)

  const { getInitialValue } = useConvertQuillToLexical({ data: json })

  const initialConfig = {
    namespace: 'TraaceEditor',
    theme,
    nodes: [ImageNodeDecorator, HeadingNode, LinkNode, AutoLinkNode],
    editable: !disabled,
    editorState: (lexicalEditor: LexicalEditor) => {
      if (isLexicalJson(json)) {
        lexicalEditor.setEditorState(lexicalEditor.parseEditorState(json))
      } else {
        getInitialValue()
      }
    },
    onError: (error: any) => console.error(error)
  }

  const onEditorChange = (editorState: EditorState) => {
    if ($isEmpty(editorState)) {
      onChange('')
    } else {
      onChange(JSON.stringify(editorState.toJSON()))
    }
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className="border border-border has-focus:border-primary rounded-lg overflow-hidden">
        {!disabled ? <EditorToolbarPlugin /> : ''}
        <OnChangePlugin onChange={onEditorChange} />
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              className={classNames(className, 'p-4 focus:border-primary outline-0', {
                'bg-white': !disabled
              })}
            />
          }
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <LinkPlugin validateUrl={validateUrl} />
        <HistoryPlugin />
        <ResetStyleOnEnterPlugin />
      </div>
    </LexicalComposer>
  )
}
