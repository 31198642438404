import { Theme, type ThemeColorsType, type ThemeType } from 'src/app/theme/type'
import { getDesignColors } from 'src/app/theme/common'
import { legacyAppTheme } from 'src/app/theme/theme'

const colors: ThemeColorsType = {
  primary: '#20325c',
  brand: '#20325c',
  icon1: '#628cec',
  active: '#e9ebef', // TODO check value
  // TODO replace with the correct colors
  sidebarBackground: '#20325c',
  sidebarForeground: '#575756',
  sidebarAccent: '#E5E5E4',
  sidebarAccentForeground: '#1A1A19',
  background: '#fff',
  white: '#fff',
  foreground: '#000',
  foregroundTitle: '#20325c',
  warning: '#ef9e3d',
  warningForeground: '#ef9e3d',
  error: '#98153b',
  errorForeground: '#98153b',
  success: '#099d72',
  successForeground: '#099d72',
  info: '#1061c2',
  infoForeground: '#1061c2',
  border: '#f0f0f0',
  mutedForeground: '#80807E',
  muted: '#FAFAF8',
  secondary: '#FFFFFF',
  secondaryForeground: '#1A1A19',
  accent: '#FAFAF8',
  linkForeground: '#3274A0',
  chart1: '#5EBDF9',

  // TODO used to be removed
  backgroundLight: '#f7f9fa',
  primaryOnDark: '#6bc4aa',
  errorOnDark: '#c17389',
  darkgreenDarker: '#314c8c',
  darkgreenBase: '#20325c',
  darkgreenLighter1: '#4063b6',
  darkgreenLighter2: '#224087',
  lightgreenBase: '#628cec',
  lightgreenLighter1: '#628CECFF',
  lightgreenLighter2: '#cbfdef',
  purpleDarker: '#120f23',
  purpleBase: '#2b225a',
  purpleLighter1: '#484073',
  purpleLighter2: '#78719e',
  purpleLighter3: '#a29dbc',
  purpleLighter4: '#f1f0f3',
  sandDarker: '#ef9e3d',
  sandBase: '#ffcf97',
  sandLighter1: '#ffe5c6',
  sandLighter2: '#fff4e6',
  greengrey80: '#101414',
  greengrey70: '#191f1d',
  greengrey60: '#212927',
  greengrey50: '#293331',
  greengrey40: '#5f6664',
  greengrey30: '#949998',
  greengrey20: '#c9cccb',
  greengrey10: '#dfe0e0',
  greengrey8: '#eeefef',
  greengrey5: '#f5f5f5',
  greengrey2: '#fbfbfb',
  ...getDesignColors()
}

export const tennaxiaTheme: ThemeType = {
  name: Theme.Tennaxia,
  colors,
  images: {
    logoUrl: '/images/logo_tennaxia.svg',
    compactLogoUrl: '/images/logo_tennaxia_mini.svg'
  },
  config: legacyAppTheme(colors)
}
