import { Theme, type ThemeColorsType, type ThemeType } from 'src/app/theme/type'
import { getDesignColors } from 'src/app/theme/common'
import { legacyAppTheme } from 'src/app/theme/theme'

const colors: ThemeColorsType = {
  primary: '#01826b',
  brand: '#01826b',
  icon1: '#0bd89d',
  active: '#E6F3F0',
  sidebarBackground: '#00372d',
  sidebarForeground: '#dfe0e0',
  sidebarAccent: '#01826b',
  sidebarAccentForeground: '#fbfbfb',
  warning: '#FEFAC3',
  warningForeground: '#ef9e3d',
  error: '#FEE2E2',
  errorForeground: '#98153b',
  info: '#EAF4F8',
  infoForeground: '#1061c2',
  success: '#F0FDEE',
  successForeground: '#099d72',
  background: '#f5f5f5',
  white: '#fff',
  foreground: '#000',
  foregroundTitle: '#01826b',
  border: '#d9d9d9',
  mutedForeground: '#80807E',
  muted: '#FAFAF8',
  secondary: '#E4E7EB',
  secondaryForeground: '#1A1A19',
  accent: '#FAFAF8',
  linkForeground: '#0bd89d',
  chart1: '#5EBDF9',

  // TODO used to be removed
  backgroundLight: '#f7f9fa',
  primaryOnDark: '#6bc4aa',
  errorOnDark: '#c17389',
  lightgreenBase: '#0bd89d',
  darkgreenDarker: '#01201a',
  darkgreenBase: '#00372d',
  darkgreenLighter1: '#01826b',
  darkgreenLighter2: '#01b292',
  lightgreenLighter1: '#67eac4',
  lightgreenLighter2: '#cbfdef',

  purpleDarker: '#120f23',
  purpleBase: '#2b225a',
  purpleLighter1: '#484073',
  purpleLighter2: '#78719e',
  purpleLighter3: '#a29dbc',
  purpleLighter4: '#f1f0f3',
  sandDarker: '#ef9e3d',
  sandBase: '#ffcf97',
  sandLighter1: '#ffe5c6',
  sandLighter2: '#fff4e6',
  greengrey80: '#101414',
  greengrey70: '#191f1d',
  greengrey60: '#212927',
  greengrey50: '#293331',
  greengrey40: '#5f6664',
  greengrey30: '#949998',
  greengrey20: '#c9cccb',
  greengrey10: '#dfe0e0',
  greengrey8: '#eeefef',
  greengrey5: '#f5f5f5',
  greengrey2: '#fbfbfb',
  ...getDesignColors()
}

export const traaceTheme: ThemeType = {
  name: Theme.Traace,
  colors,
  images: {
    logoUrl: '/images/logo.svg',
    compactLogoUrl: '/images/logo-mini.svg'
  },
  config: legacyAppTheme(colors)
}
