import React, { type PropsWithChildren, useEffect } from 'react'
import { setTwoToneColor } from '@ant-design/icons'
import { ConfigProvider } from 'antd'
import antEnLocale from 'antd/locale/en_GB'
import { useTypedTranslation } from 'src/common/utils'
import kebabCase from 'lodash/fp/kebabCase'
import type { ThemeType } from 'src/app/theme/type'
import { antdLocales } from 'src/app/antdLocales'

type GenericAntConfigProviderProps = PropsWithChildren & Readonly<{ theme: ThemeType }>

export function AntConfigProvider({ children, theme }: GenericAntConfigProviderProps) {
  const { i18n } = useTypedTranslation()

  useEffect(() => {
    setTwoToneColor(theme.colors.icon1)
    const root = document.documentElement
    Object.entries(theme.colors).forEach(([key, value]) => {
      root.style.setProperty(`--theme-color-${kebabCase(key)}`, value)
    })
  }, [theme])

  return (
    <ConfigProvider locale={antdLocales[i18n.language] ?? antEnLocale} theme={theme.config}>
      {children}
    </ConfigProvider>
  )
}
