import { type CSSProperties } from 'react'
import { compareStringsNormalized, normalizeStringForSearch } from 'src/common/utils/stringNormalizer'
import escapeRegExp from 'lodash/escapeRegExp'

export const highlightText = (
  text: string,
  highlightWord?: string,
  highlightStyle: CSSProperties = {
    fontWeight: 'bold'
  }
) => {
  if (!highlightWord) return <span>{text}</span>

  const normalizedText = normalizeStringForSearch(text)
  const normalizedHighlightWord = normalizeStringForSearch(highlightWord)
  const regex = new RegExp(`(${escapeRegExp(normalizedHighlightWord)})`, 'gi')
  const parts = normalizedText.split(regex)

  let originalIndex = 0

  return (
    <>
      {parts.map(part => {
        const isHighlighted = compareStringsNormalized(part, normalizedHighlightWord)
        const originalPart = text.substring(originalIndex, originalIndex + part.length)
        originalIndex += part.length

        return isHighlighted ? (
          <span style={highlightStyle} key={`${originalIndex}_highlighted`}>
            {originalPart}
          </span>
        ) : (
          <span key={originalIndex}>{originalPart}</span>
        )
      })}
    </>
  )
}
