import React, { useEffect } from 'react'
import { type TreeSelectProps as AntTreeSelectProps, TreeSelect as AntTreeSelect, Skeleton } from 'antd'
import { WarningOutlined } from '@ant-design/icons'
import { useTypedTranslation } from 'src/common/utils'
import type { DataNode } from 'rc-tree-select/lib/interface'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'

export function TreeSelect<ValueType = any, OptionType extends DataNode = DataNode>({
  error,
  ...props
}: AntTreeSelectProps<ValueType, OptionType> &
  Readonly<{
    error?: any
  }>) {
  const { t } = useTypedTranslation()
  const [isFetching, setIsFetching] = React.useState(props.loading)
  const isNewBrandingEnabled = useOverridableFlag(UnleashFlag.NewBranding)
  useEffect(() => {
    if (isFetching && !props.loading) {
      setIsFetching(false)
    }
  }, [props.loading, isFetching])
  if (error) {
    return (
      <AntTreeSelect
        {...props}
        status="error"
        suffixIcon={<WarningOutlined className="text-error-foreground" />}
        value={undefined}
        placeholder={t('common.error_message')}
      />
    )
  }
  if (isFetching) {
    return (
      <Skeleton.Input
        active
        size={isNewBrandingEnabled ? 'default' : 'large'}
        className={props.className}
        style={props.style}
      />
    )
  }
  return <AntTreeSelect<ValueType, OptionType> {...props} />
}

TreeSelect.SHOW_PARENT = AntTreeSelect.SHOW_PARENT
TreeSelect.SHOW_ALL = AntTreeSelect.SHOW_ALL
TreeSelect.SHOW_CHILD = AntTreeSelect.SHOW_CHILD
