import React from 'react'
import { ANALYZE_MENU, ROOT } from 'src/navigation/routes'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { usePermissions } from 'src/navigation/_hooks'
import { useTypedTranslation } from 'src/common/utils'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { notNil } from 'src/common/utils/notNil'
import type { ItemType } from 'antd/es/menu/interface'
import { DatabaseIcon, FileDownloadIcon, SquareLayoutGridIcon } from 'src/icons'

export function useAnalyzeMenu(): ItemType | null {
  const { canAccessAnalyze, canReadAnalyses } = usePermissions()
  const { t } = useTypedTranslation()
  const isAsyncExportsEnabled = useOverridableFlag(UnleashFlag.AsyncExports)

  const children: ItemType[] = [
    canReadAnalyses
      ? {
          key: `${ANALYZE_MENU} ${ROOT}/dataCollection/*`,
          icon: <DatabaseIcon />,
          label: <OrganizationLink to="/dataCollection">{t('menu.analyses')}</OrganizationLink>
        }
      : undefined,
    canAccessAnalyze
      ? {
          key: `${ANALYZE_MENU} ${ROOT}/analyses/dashboards/*`,
          icon: <SquareLayoutGridIcon />,
          label: <OrganizationLink to="/analyses/dashboards">{t('menu.dashboards')}</OrganizationLink>
        }
      : undefined,
    canReadAnalyses && isAsyncExportsEnabled
      ? {
          key: `${ANALYZE_MENU}  ${ROOT}/analyses/exports/*`,
          icon: <FileDownloadIcon />,
          label: <OrganizationLink to="/analyses/exports">{t('menu.exports')}</OrganizationLink>
        }
      : undefined
  ].filter(notNil)

  if (children.length === 0) return null
  return {
    key: ANALYZE_MENU,
    type: 'group',
    label: t('menu.analyze'),
    children
  }
}
