import { create } from 'zustand'
import type { IToggle } from '@unleash/proxy-client-react'

type FlagState = {
  flags: IToggle[]
  populateFlags: (flags: IToggle[]) => void
  updateFlag: (flagName: string, isEnabled: boolean) => void
}

export const useFlagStore = create<FlagState>()(set => ({
  flags: [],
  populateFlags: (flags: IToggle[]) => set({ flags }),
  updateFlag: (flagName: string, isEnabled: boolean) =>
    set(state => ({
      flags: state.flags.map(flag => (flag.name === flagName ? { ...flag, enabled: isEnabled } : flag))
    }))
}))
