import { type PropsWithChildren, useEffect, useMemo, useRef } from 'react'
import { ExportExecutionProgressContext } from 'src/provider/ExportExecutionProgressContext'
import { notification } from 'antd'
import { ExportExecutionStatus, useGetExportExecutionLazyQuery } from 'src/generated/graphql/types'
import { LoadingOutlined } from '@ant-design/icons'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { useTypedTranslation } from 'src/common/utils'
import { useApolloClient } from '@apollo/client'

const TIMEOUT_INTERVAL = 5000

export function ExportExecutionProgressProvider({ children }: PropsWithChildren) {
  const { t } = useTypedTranslation()
  const client = useApolloClient()
  const ongoingExports = useRef<string[]>([])
  const [api, contextHolder] = notification.useNotification()
  const timeout = useRef<NodeJS.Timeout>(null)
  const [getExportExecution] = useGetExportExecutionLazyQuery({ fetchPolicy: 'no-cache' })
  const value = useMemo(
    () => ({
      ongoingExports: ongoingExports.current,
      addOngoingExport: ({ id, fileName }: { id: string; fileName: string }) => {
        ongoingExports.current = [...ongoingExports.current, id]
        api.info({
          icon: <LoadingOutlined />,
          placement: 'topRight',
          message: t('exports.executions.notifications.started'),
          description: fileName,
          key: `export-exec-${id}`,
          duration: 0
        })
      }
    }),
    []
  )

  const pollExecutions = () => {
    ongoingExports.current.forEach(async exportId => {
      const { data } = await getExportExecution({ variables: { id: exportId } })
      const execution = data?.getExportExecution
      if (execution && execution.status !== ExportExecutionStatus.InProgress) {
        const notificationFn = execution.status === ExportExecutionStatus.Completed ? api.success : api.error
        api.destroy(`export-exec-${execution.id}`)
        notificationFn({
          placement: 'topRight',
          message: t(`exports.executions.notifications.${execution.status}`),
          description: <OrganizationLink to="/analyses/exports/list">{execution.fileName}</OrganizationLink>,
          duration: 10,
          showProgress: true
        })
        client.cache.evict({ fieldName: 'listExportExecutions' })
        client.cache.gc()
        ongoingExports.current = ongoingExports.current.filter(id => id !== exportId)
      }
    })
    timeout.current = setTimeout(pollExecutions, TIMEOUT_INTERVAL)
  }

  useEffect(() => {
    timeout.current = setTimeout(pollExecutions, TIMEOUT_INTERVAL)
    return () => {
      if (timeout.current) clearTimeout(timeout.current)
    }
  }, [])

  return (
    <ExportExecutionProgressContext.Provider value={value}>
      <>
        {contextHolder}
        {children}
      </>
    </ExportExecutionProgressContext.Provider>
  )
}
