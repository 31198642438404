import type { ThemeConfig } from 'antd'

export type ThemeColorsType = DesignSystemColorsType & {
  warning: string
  warningForeground: string
  error: string
  errorForeground: string
  success: string
  successForeground: string
  primary: string
  brand: string
  sidebarBackground: string
  sidebarForeground: string
  sidebarAccent: string
  sidebarAccentForeground: string
  icon1: string
  active: string
  foreground: string
  foregroundTitle: string
  border: string
  mutedForeground: string
  muted: string
  secondary: string
  secondaryForeground: string
  accent: string
  linkForeground: string
  chart1: string

  // TODO to be removed
  info: string
  infoForeground: string
  primaryOnDark: string
  errorOnDark: string
  darkgreenDarker: string
  darkgreenBase: string
  darkgreenLighter1: string
  darkgreenLighter2: string
  lightgreenBase: string
  lightgreenLighter1: string
  lightgreenLighter2: string
  purpleDarker: string
  purpleBase: string
  purpleLighter1: string
  purpleLighter2: string
  purpleLighter3: string
  purpleLighter4: string
  sandDarker: string
  sandBase: string
  sandLighter1: string
  sandLighter2: string
  greengrey80: string
  greengrey70: string
  greengrey60: string
  greengrey50: string
  greengrey40: string
  greengrey30: string
  greengrey20: string
  greengrey10: string
  greengrey8: string
  greengrey5: string
  greengrey2: string
  backgroundLight: string
  background: string
  white: string
}

export type DesignSystemColorsType = {
  // Design system
  neutral100: string
  neutral200: string
  neutral300: string
  neutral400: string
  neutral500: string
  neutral600: string
  neutral700: string
  neutral800: string
  neutral900: string
  neutral1000: string
  red100: string
  red200: string
  red300: string
  red400: string
  red500: string
  red600: string
  red700: string
  red800: string
  red900: string
  red1000: string
}

type ThemeImagesType = {
  logoUrl: string
  compactLogoUrl: string
}

export enum Theme {
  Tennaxia = 'TENNAXIA',
  Traace = 'TRAACE',
  Light = 'LIGHT',
  Dark = 'DARK'
}

export type ThemeType = {
  name: Theme
  colors: ThemeColorsType
  images: ThemeImagesType
  config: ThemeConfig
}
