import React from 'react'
import { useTypedTranslation } from 'src/common/utils'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { MY_TASKS_MENU, ROOT } from 'src/navigation/routes'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { type GetProp, Menu } from 'antd'
import { useCountCurrentUserActiveCampaignTasksQuery } from 'src/generated/graphql/types'
import { CheckboxCheckedIcon } from 'src/icons'

export function useMyTasksMenu(): GetProp<typeof Menu, 'items'>[number] | null {
  const { t } = useTypedTranslation()
  const isCampaignsRelease = useOverridableFlag(UnleashFlag.ReleaseCampaigns)

  const { data } = useCountCurrentUserActiveCampaignTasksQuery()
  const todoCount = data?.countCurrentUserActiveCampaignTasks || 0

  return isCampaignsRelease
    ? {
        key: `${MY_TASKS_MENU} ${ROOT}/mytasks`,
        icon: <CheckboxCheckedIcon />,
        label: (
          <div className="flex justify-between items-center">
            <OrganizationLink to="/mytasks">{t('my_tasks.title')}</OrganizationLink>
            {todoCount ? (
              <span className="rounded bg-primary text-white h-5 mx-1 min-w-5 flex items-center justify-center">
                <span className="drop-shadow-md text-xs">{todoCount}</span>
              </span>
            ) : null}
          </div>
        )
      }
    : null
}
