import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { CAMPAIGN_MENU, ROOT } from 'src/navigation/routes'
import { usePermissions } from 'src/navigation/_hooks'
import type { ItemType } from 'antd/es/menu/interface'
import { CalendarCheckIcon } from 'src/icons'

export const useCampaignsMenu = (): ItemType | null => {
  const { t } = useTypedTranslation()
  const { canManageCampaigns, canAssignCampaignTask } = usePermissions()
  const isCampaignsRelease = useOverridableFlag(UnleashFlag.ReleaseCampaigns)

  const children: ItemType[] = []

  if (isCampaignsRelease) {
    if (canManageCampaigns || canAssignCampaignTask) {
      children.push({
        key: `${CAMPAIGN_MENU} ${ROOT}/campaigns/*`,
        label: <OrganizationLink to="/campaigns">{t('menu.campaigns_list')}</OrganizationLink>
      })
    }
    if (canManageCampaigns) {
      children.push({
        key: `${CAMPAIGN_MENU} ${ROOT}/questionnaires/*`,
        label: <OrganizationLink to="/questionnaires">{t('menu.questionnaires')}</OrganizationLink>
      })
    }
  }

  if (children.length === 0) return null

  return {
    key: CAMPAIGN_MENU,
    icon: <CalendarCheckIcon />,
    label: t('menu.campaigns'),
    children
  }
}
