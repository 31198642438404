import inRange from 'lodash/inRange'
import { useTypedTranslation } from 'src/common/utils'

type notationProps = 'standard' | 'scientific' | 'engineering' | 'compact' | undefined
const getPercentageMaximumFractionDigits = (value: number): number => {
  switch (true) {
    case value >= 1 || value === 0:
      return 0
    case value >= 0.1:
      return 1
    case value >= 0.0001 && value < 0.1:
      return 2
    default:
      return 0
  }
}

function truncateNumber(value: number, digits: number): number {
  if (value < 0.9999 && value >= 0.01) {
    digits += 1
  }
  const factor = new Array(digits).fill(0).reduce(acc => acc * 10, 100)
  return Math.floor(value * factor) / factor
}

function getRoundingPrefix(value: number): string {
  return value && inRange(value, -0.011, 0.011) && value ? '~' : ''
}

export function formatPercentValue(value: number, language: string): string {
  const digits = getPercentageMaximumFractionDigits(value)
  const truncatedValue = truncateNumber(value, digits)
  const percentFormatter = new Intl.NumberFormat(language || 'en', {
    style: 'percent',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  })
  return `${getRoundingPrefix(value * 100)}${percentFormatter.format(truncatedValue)}`
}

function getNumberFormatOptions(value: number): Intl.NumberFormatOptions {
  const absValue = Math.abs(value)
  switch (true) {
    case absValue >= 100000000:
      return { notation: 'compact' }
    case absValue >= 1000000:
      return { notation: 'compact', maximumFractionDigits: 1, minimumFractionDigits: 0 }
    case absValue >= 100000:
      return { notation: 'compact' }
    case absValue >= 10000:
      return { notation: 'compact', maximumFractionDigits: 1, minimumFractionDigits: 0 }
    case absValue >= 1000:
      return { notation: 'compact', maximumFractionDigits: 2, minimumFractionDigits: 0 }
    case absValue >= 10:
      return { maximumFractionDigits: 1, minimumFractionDigits: 1 }
    case absValue >= 0.01:
      return { maximumFractionDigits: 2, minimumFractionDigits: 2 }
    default:
      return { maximumFractionDigits: 0, minimumFractionDigits: 0 }
  }
}

function getMoneyFormatOptions(value: number): Intl.NumberFormatOptions {
  const absValue = Math.abs(value)
  switch (true) {
    case absValue >= 10:
      return { notation: 'compact', maximumFractionDigits: 0, minimumFractionDigits: 0 }
    case absValue >= 1:
      return { maximumFractionDigits: 2, minimumFractionDigits: 0 }
    default:
      return { maximumFractionDigits: 3, minimumFractionDigits: 0 }
  }
}

export function toFormattedNumber(value: number, language: string): string {
  const options = getNumberFormatOptions(value)
  return `${getRoundingPrefix(value)}${Intl.NumberFormat(language || 'en', options).format(value)}`.replaceAll('K', 'k')
}

const toDetailedNumber = (value: number, language: string, fractionDigits = 3): string => {
  return Intl.NumberFormat(language || 'en', {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits
  }).format(value)
}

export function useDashboardNumbers() {
  const { i18n } = useTypedTranslation()
  return {
    toPercent: (value: number): string => {
      return formatPercentValue(value, i18n.language || 'en')
    },
    toNumber(value: number): string {
      return toFormattedNumber(value, i18n.language || 'en')
    },
    toDetailedNumber: (value: number): string => {
      return toDetailedNumber(value, i18n.language || 'en')
    },
    toTwoDecimalNumber(value: number): string {
      return toDetailedNumber(value, i18n.language || 'en', 2)
    },
    toMoney: (
      value: number,
      options?: {
        maximumFractionDigits?: number
        notation?: notationProps
      }
    ) => {
      return Intl.NumberFormat(i18n.language || 'en', {
        style: 'currency',
        currency: 'EUR',
        ...getMoneyFormatOptions(value),
        ...options
      }).format(value)
    }
  }
}
