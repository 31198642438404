import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { type ReactNode } from 'react'
import { PermissionName } from 'src/generated/graphql/types'
import { UnleashFlag } from './UnleashFlags'

export interface PermissionItem {
  i18nKey: PermissionGroupList | PermissionName | PermissionCategoryList
  checkable?: boolean
  icon?: ReactNode
  children?: PermissionItem[]
}

interface Permission extends PermissionItem {
  children: PermissionItem[]
}

export enum PermissionColor {
  SCOPED = 'bg-yellow-400',
  CATEGORIZED = 'bg-indigo-400'
}

// Use with i18n
export enum PermissionCategoryList {
  SETTINGS = 'SETTINGS',
  DATA_COLLECTION = 'DATA_COLLECTION',
  REDUCE = 'REDUCE'
}

export enum PermissionGroupList {
  CUSTOM_EMISSIONS_FACTORS = 'CUSTOM_EMISSIONS_FACTORS',
  CATEGORIES = 'CATEGORIES',
  KPIS = 'KPIS',
  TEAM = 'TEAM',
  ORGANIZATION = 'ORGANIZATION',
  ROLES = 'ROLES',
  SUPPLY_CHAIN = 'SUPPLY_CHAIN',
  ANALYSES = 'ANALYSES',
  ACTIONS_CATALOG = 'ACTIONS_CATALOG',
  TRAJECTORY = 'TRAJECTORY',
  ACTION_PLANS = 'ACTION_PLANS',
  ANALYSIS_DETAILS = 'ANALYSIS_DETAILS',
  CONNECT = 'CONNECT',
  CAMPAIGNS = 'CAMPAIGNS',
  ANALYSIS = 'ANALYSIS',
  EMISSIONS_ITEM_DETAILS = 'EMISSIONS_ITEM_DETAILS',
  MANUAL_ENTRIES = 'MANUAL_ENTRIES',
  LCAS = 'LCAS',
  REPORTING = 'REPORTING'
}

const SETTINGS_PERMISSIONS_TREE = {
  i18nKey: PermissionCategoryList.SETTINGS,
  checkable: false,
  children: [
    {
      i18nKey: PermissionGroupList.CUSTOM_EMISSIONS_FACTORS,
      checkable: false,
      children: [{ i18nKey: PermissionName.ReadEmissionFactor }, { i18nKey: PermissionName.ManageEmissionFactor }]
    },
    {
      i18nKey: PermissionGroupList.CATEGORIES,
      checkable: false,
      children: [{ i18nKey: PermissionName.ReadCategory }, { i18nKey: PermissionName.ManageCategory }]
    },
    {
      i18nKey: PermissionGroupList.TEAM,
      checkable: false,
      children: [{ i18nKey: PermissionName.ReadUser }, { i18nKey: PermissionName.ManageUser }]
    },
    {
      i18nKey: PermissionGroupList.ORGANIZATION,
      checkable: false,
      children: [
        { i18nKey: PermissionName.ManageOrganizationHierarchy },
        { i18nKey: PermissionName.ManageDatapointTag },
        { i18nKey: PermissionName.ManageOrganizationSettings },
        { i18nKey: PermissionName.ManageContributingEntityIndicatorData }
      ]
    },
    {
      i18nKey: PermissionGroupList.ROLES,
      checkable: false,
      children: [{ i18nKey: PermissionName.ReadRole }, { i18nKey: PermissionName.ManageRole }]
    }
  ]
}

function getAnalysisTree() {
  return {
    i18nKey: PermissionGroupList.ANALYSIS,
    checkable: false,
    children: [{ i18nKey: PermissionName.ReadAnalysisDashboard }, { i18nKey: PermissionName.CreateAnalysisDashboard }]
  }
}

const ACTION_PLAN_PERMISSION_TREE = {
  i18nKey: PermissionCategoryList.REDUCE,
  checkable: false,
  children: [
    {
      i18nKey: PermissionGroupList.ACTIONS_CATALOG,
      checkable: false,
      children: [{ i18nKey: PermissionName.ReadAction }, { i18nKey: PermissionName.ManageAction }]
    },
    {
      i18nKey: PermissionGroupList.TRAJECTORY,
      checkable: false,
      children: [{ i18nKey: PermissionName.ReadTrajectoryAndForecast }, { i18nKey: PermissionName.ManageTrajectory }]
    },
    {
      i18nKey: PermissionGroupList.ACTION_PLANS,
      checkable: false,
      children: [
        { i18nKey: PermissionName.ReadActionPlan },
        { i18nKey: PermissionName.CreateAndEditActionPlan },
        { i18nKey: PermissionName.PublishActionPlan }
      ]
    }
  ]
}
const SUPPLY_CHAIN_PERMISSION_TREE = {
  i18nKey: PermissionGroupList.SUPPLY_CHAIN,
  checkable: false,
  children: [
    { i18nKey: PermissionName.ReadSuppliers },
    { i18nKey: PermissionName.ManageSuppliers },
    { i18nKey: PermissionName.ManageSupplierAttributesAndScoring }
  ]
}

const LCAS_PERMISSION_TREE = {
  i18nKey: PermissionGroupList.LCAS,
  checkable: false,
  children: [{ i18nKey: PermissionName.ManageLcas }]
}

const REPORTING_PERMISSION_TREE = {
  i18nKey: PermissionGroupList.REPORTING,
  checkable: false,
  children: [{ i18nKey: PermissionName.ReadAndEditReport }]
}

export function usePermissionTree(): Permission[] {
  const isSupplyChainEnabled = useOverridableFlag(UnleashFlag.ModuleSupplyChainEnabled)
  const isReduceModuleEnabled = useOverridableFlag(UnleashFlag.ModuleReduceEnabled)
  const isCampaignsRelease = useOverridableFlag(UnleashFlag.ReleaseCampaigns)
  const isLCAModuleEnabled = useOverridableFlag(UnleashFlag.ModuleLCAEnabled)
  const isHideEmissionItemList = useOverridableFlag(UnleashFlag.HideEmissionItemList)
  const isExtrapolationRelease = useOverridableFlag(UnleashFlag.ReleaseExtrapolations)
  const isModuleReportingEnabled = useOverridableFlag(UnleashFlag.ModuleReporting)
  const isManualEntriesEnabled = useOverridableFlag(UnleashFlag.ManualEntries)

  const DATA_COLLECTION_PERMISSION_TREE = {
    i18nKey: PermissionCategoryList.DATA_COLLECTION,
    checkable: false,
    children: [
      {
        i18nKey: PermissionGroupList.ANALYSES,
        checkable: false,
        children: [
          { i18nKey: PermissionName.ReadAnalyses },
          { i18nKey: PermissionName.ManageAnalyses },
          ...(isExtrapolationRelease ? [{ i18nKey: PermissionName.ManageExtrapolation }] : [])
        ]
      },
      ...(isHideEmissionItemList
        ? []
        : [
            {
              i18nKey: PermissionGroupList.ANALYSIS_DETAILS,
              checkable: false,
              children: [
                { i18nKey: PermissionName.ManageContributingSource },
                { i18nKey: PermissionName.ReadEmissionItemDetail },
                { i18nKey: PermissionName.AddDataPoint },
                { i18nKey: PermissionName.ValidateData }
              ]
            }
          ]),
      ...(isManualEntriesEnabled
        ? [
            {
              i18nKey: PermissionGroupList.MANUAL_ENTRIES,
              checkable: false,
              children: [{ i18nKey: PermissionName.ManageManualEntry }, { i18nKey: PermissionName.UpdateManualEntry }]
            }
          ]
        : []),
      {
        i18nKey: PermissionGroupList.CONNECT,
        checkable: false,
        children: [
          { i18nKey: PermissionName.ReadUpload },
          { i18nKey: PermissionName.ConfigureMapping },
          { i18nKey: PermissionName.UploadFile }
        ]
      },
      ...(isCampaignsRelease
        ? [
            {
              i18nKey: PermissionGroupList.CAMPAIGNS,
              checkable: false,
              children: [
                { i18nKey: PermissionName.ManageCampaign },
                { i18nKey: PermissionName.AssignCampaignTask },
                { i18nKey: PermissionName.OverrideCampaignTaskResponse }
              ]
            }
          ]
        : [])
    ]
  }

  return [
    SETTINGS_PERMISSIONS_TREE,
    DATA_COLLECTION_PERMISSION_TREE,
    getAnalysisTree(),
    isReduceModuleEnabled ? ACTION_PLAN_PERMISSION_TREE : null,
    isSupplyChainEnabled ? SUPPLY_CHAIN_PERMISSION_TREE : null,
    isLCAModuleEnabled ? LCAS_PERMISSION_TREE : null,
    isModuleReportingEnabled ? REPORTING_PERMISSION_TREE : null
  ].filter(permissionTree => permissionTree !== null) as Permission[]
}
