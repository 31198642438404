import { useContext } from 'react'
import { OrganizationConfigContext } from 'src/provider/ConfigContext'

export const useOrganizationConfig = () => {
  const context = useContext(OrganizationConfigContext)
  if (context === undefined) {
    throw new Error('useOrganizationConfig must be used within a OrganizationConfigProvider')
  }
  return context
}
