import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache, type Operation, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { type Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react'
import { createClient } from 'graphql-ws'
import { useMemo } from 'react'
import { addDatadogError } from 'src/services/rum'
import { PreferredLanguageLocalStorageKey } from '../utils/PreferredLanguageDetector'
import { TRAACE_IMPERSONATE_EMAIL_HEADER, TRAACE_ORGANIZATION_ID_HEADER } from 'src/common/apollo/apolloConstants'
import { useMessageAPI } from 'src/provider/useMessageAPI'
import type { CustomMessageInstance } from 'src/provider/MessageAPIContext'
import { OperationTypeNode } from 'graphql/language'

type ApolloClientParams = {
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']
  organizationId?: string
  impersonateEmail?: string
  message: CustomMessageInstance
}

const isMutation = (operation: Operation) =>
  operation.query.definitions.some(
    definition => definition.kind === 'OperationDefinition' && definition.operation === OperationTypeNode.MUTATION
  )

export const errorLink = (message: CustomMessageInstance) =>
  onError(({ networkError, graphQLErrors, operation }) => {
    const firstGraphqlError = graphQLErrors?.[0]
    if (firstGraphqlError && firstGraphqlError.extensions?.code !== 'FORBIDDEN' && !isMutation(operation)) {
      message.apiError(firstGraphqlError)
    }
    if (networkError) {
      console.error('[Network error]', networkError)
      addDatadogError(networkError, { errorType: 'networkError' })
    }
  })

function authMiddleware({
  getAccessTokenSilently,
  organizationId,
  impersonateEmail
}: {
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']
  organizationId?: string
  impersonateEmail?: string
}) {
  return setContext(async (operation, { headers, adminQuery }) => {
    const token = await getAccessTokenSilently()

    const headersToSend = {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
    if ((!headers || !headers[TRAACE_ORGANIZATION_ID_HEADER]) && organizationId && !adminQuery) {
      headersToSend[TRAACE_ORGANIZATION_ID_HEADER] = organizationId
    }
    if ((!headers || !headers[TRAACE_IMPERSONATE_EMAIL_HEADER]) && impersonateEmail) {
      headersToSend[TRAACE_IMPERSONATE_EMAIL_HEADER] = impersonateEmail
    }
    const languageOverride = localStorage.getItem(PreferredLanguageLocalStorageKey)
    if (languageOverride) {
      headersToSend['language-override'] = languageOverride
    }

    return {
      headers: headersToSend
    }
  })
}

export const httpLink = createHttpLink({ uri: import.meta.env.VITE_GRAPHQL_API_URL })

export function generateApolloLink(params: ApolloClientParams) {
  return from([authMiddleware(params), errorLink(params.message), httpLink])
}

export function generateApolloWebsocketLink(params: {
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently']
  organizationId?: string
  impersonateEmail?: string
}): ApolloLink {
  return ApolloLink.from([
    new RetryLink(),
    new GraphQLWsLink(
      createClient({
        url: import.meta.env.VITE_GRAPHQL_WS_API_URL!,
        connectionParams: async () => {
          return {
            isWebSocket: true,
            headers: {
              authorization: `Bearer ${await params.getAccessTokenSilently()}`,
              [TRAACE_ORGANIZATION_ID_HEADER]: params.organizationId,
              [TRAACE_IMPERSONATE_EMAIL_HEADER]: params.impersonateEmail
            }
          }
        }
      })
    )
  ])
}

/**
 * Query/Mutations use HTTP requests and subscription use Websocket.
 * => We create one HTTP connection and one WebSocket connection
 * https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
 */
export function splitLink(wsLink: ApolloLink, httpApolloLink: ApolloLink): ApolloLink {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpApolloLink
  )
}

export function useAnonymousApolloClient() {
  const message = useMessageAPI()
  return useMemo(
    () =>
      new ApolloClient({
        devtools: { enabled: true },
        cache: new InMemoryCache(),
        link: from([errorLink(message), httpLink])
      }),
    []
  )
}

export function getAuthentifiedApolloClient(params: ApolloClientParams) {
  return new ApolloClient({
    devtools: { enabled: true },
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            notifications: {
              keyArgs: false,
              merge(existing, incoming, { args }) {
                if (!existing) {
                  return incoming
                }
                if (!args) {
                  throw new Error('No args in paginated query!')
                }
                const offset = args['offset']
                const mergedResults = existing.results ? existing.results.slice(0) : []
                for (let i = 0; i < incoming.results.length; ++i) {
                  mergedResults[offset + i] = incoming.results[i]
                }
                return { ...incoming, results: mergedResults }
              }
            },
            searchUnsplash: {
              keyArgs: ['searchQuery'],
              merge(existing, incoming, { args }) {
                if (!existing) {
                  return incoming
                }
                if (!args) {
                  throw new Error('No args in paginated query!')
                }
                const offset = args['offset']
                const mergedResults = existing.results ? existing.results.slice(0) : []
                for (let i = 0; i < incoming.results.length; ++i) {
                  mergedResults[offset + i] = incoming.results[i]
                }
                return { ...incoming, results: mergedResults }
              }
            },
            emissionsItemsCategory: {
              keyArgs: ['categoryId'],
              merge(existing, incoming) {
                if (!existing) {
                  return incoming
                }
                const result = { ...incoming }
                if (!incoming.children && existing.children) {
                  result.children = existing.children
                }
                return result
              }
            },
            perimeterRelatedToPermission: {
              keyArgs: ['permission']
            }
          }
        }
      }
    }),
    link: splitLink(generateApolloWebsocketLink(params), generateApolloLink(params))
  })
}

export function useAuthentifiedApolloClient({
  organizationId,
  impersonateEmail
}: {
  organizationId?: string
  impersonateEmail?: string
} = {}): ApolloClient<any> {
  const { getAccessTokenSilently } = useAuth0()
  const message = useMessageAPI()
  // memoize the client so that it doesn't get recreated on every render (which can cause infinite loops)
  return useMemo(() => {
    return getAuthentifiedApolloClient({ getAccessTokenSilently, organizationId, impersonateEmail, message })
  }, [organizationId, impersonateEmail])
}
