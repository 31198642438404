import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { useOrganizationConfig } from 'src/provider'
import { MoreIcon } from 'src/icons'
import { Dropdown } from 'antd'
import { useUserMenu } from 'src/navigation/_hooks'

const Profile = () => {
  const { currentUser, organization } = useOrganizationConfig()
  const dropdownItems = useUserMenu()

  return (
    <div className="flex justify-between items-center space-x-2 h-full">
      <div className="flex flex-col overflow-hidden justify-between relative">
        <OrganizationLink to={`settings/user/${currentUser.authUser.id}`}>
          <div className="truncate cursor-pointer h-5 leading-5">{currentUser.fullName}</div>
          <div className="truncate text-muted-foreground h-5 leading-5">{organization.name}</div>
        </OrganizationLink>
      </div>
      <div className="w-4 h-4">
        <Dropdown trigger={['click']} menu={{ items: dropdownItems }}>
          <MoreIcon onClick={e => e.stopPropagation()} />
        </Dropdown>
      </div>
    </div>
  )
}

export default Profile
