import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { ADDONS_MENU, ROOT } from 'src/navigation/routes'
import type { ItemType } from 'antd/es/menu/interface'
import { usePermissions } from 'src/navigation/_hooks/usePermissions'
import React from 'react'
import { ChartPieIcon, ConnectedDotsIcon } from 'src/icons'
import { useCustomLinkMenu } from 'src/navigation/_hooks/useCustomLinkMenu'

export function useAddonsMenu(): ItemType {
  const { t } = useTypedTranslation()
  const isLCAModuleEnabled = useOverridableFlag(UnleashFlag.ModuleLCAEnabled)
  const isSupplyChainEnabled = useOverridableFlag(UnleashFlag.ModuleSupplyChainEnabled)
  const { canReadSuppliers, canManageLCAs } = usePermissions()
  const customLinkMenu = useCustomLinkMenu()

  const children: ItemType[] = []

  if (isSupplyChainEnabled && canReadSuppliers) {
    children.push({
      key: `${ADDONS_MENU} ${ROOT}/supplyChain/*`,
      icon: <ConnectedDotsIcon />,
      label: <OrganizationLink to="/supplyChain/suppliersDashboard">{t('menu.supply_chain')}</OrganizationLink>
    })
  }

  if (canManageLCAs && isLCAModuleEnabled) {
    children.push({
      key: `${ADDONS_MENU} ${ROOT}/lcaTemplates/*`,
      icon: <ChartPieIcon />,
      label: <OrganizationLink to="/lcaTemplates">{t('menu.lca')}</OrganizationLink>
    })
  }

  if (customLinkMenu) children.push(customLinkMenu)

  if (children.length === 0) return null
  return {
    key: ADDONS_MENU,
    type: 'group',
    label: t('menu.addons'),
    children: children
  }
}
