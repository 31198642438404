import React from 'react'
import { ANALYZE_MENU, DASHBOARDS_ROUTE, EXPORTS_ROUTE } from 'src/navigation/routes'
import { AuditOutlined, LayoutOutlined, TableOutlined } from '@ant-design/icons'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { usePermissions } from 'src/navigation/_hooks'
import { useTypedTranslation } from 'src/common/utils'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { notNil } from 'src/common/utils/notNil'

export function useAnalyzeMenu() {
  const { canAccessAnalyze, canReadAnalyses } = usePermissions()
  const { t } = useTypedTranslation()
  const isAsyncExportsEnabled = useOverridableFlag(UnleashFlag.AsyncExports)

  if (!isAsyncExportsEnabled) {
    if (!canAccessAnalyze) return null
    return {
      key: `${ANALYZE_MENU}/analysis`,
      icon: <LayoutOutlined />,
      label: <OrganizationLink to="/analyses/dashboards/list">{t('menu.analyze')}</OrganizationLink>
    }
  }

  const children = [
    canAccessAnalyze
      ? {
          key: `${ANALYZE_MENU} ${DASHBOARDS_ROUTE}/*`,
          icon: <AuditOutlined />,
          label: <OrganizationLink to="/analyses/dashboards/list">{t('menu.dashboards')}</OrganizationLink>
        }
      : undefined,
    canReadAnalyses
      ? {
          key: `${ANALYZE_MENU}  ${EXPORTS_ROUTE}/*`,
          icon: <TableOutlined />,
          label: <OrganizationLink to="/analyses/exports">{t('menu.exports')}</OrganizationLink>
        }
      : undefined
  ].filter(notNil)

  if (children.length === 0) return null
  return {
    key: ANALYZE_MENU,
    icon: <LayoutOutlined />,
    label: t('menu.analyze'),
    children
  }
}
