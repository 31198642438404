import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { IMPORTS_MENU, ROOT } from 'src/navigation/routes'
import { usePermissions } from 'src/navigation/_hooks'
import { type GetProp, Menu } from 'antd'
import type { ItemType } from 'antd/es/menu/interface'
import { FileDownloadIcon } from 'src/icons'

export const useImportsMenu = (): ItemType | null => {
  const { t } = useTypedTranslation()
  const { permissionsLoading, canUploadFile } = usePermissions()
  const isConnectV2Enabled = useOverridableFlag(UnleashFlag.ConnectV2)

  if (permissionsLoading) return null

  const children: GetProp<typeof Menu, 'items'> = []

  if (isConnectV2Enabled && canUploadFile) {
    children.push({
      key: `${IMPORTS_MENU} ${ROOT}/imports_v2/import`,
      label: <OrganizationLink to="/imports_v2/import">{`${t('menu.imports_list')}`}</OrganizationLink>
    })
    children.push({
      key: `${IMPORTS_MENU} ${ROOT}/imports_v2/format`,
      label: <OrganizationLink to="/imports_v2/format">{`${t('menu.imports_templates')}`}</OrganizationLink>
    })
    children.push({
      key: `${IMPORTS_MENU} ${ROOT}/imports_v2/flow`,
      label: <OrganizationLink to="/imports_v2/flow">{`${t('measure.connect.flows.title')}`}</OrganizationLink>
    })
  }

  if (children.length === 0) return null

  return {
    key: IMPORTS_MENU,
    icon: <FileDownloadIcon />,
    label: t('menu.imports'),
    children
  }
}
