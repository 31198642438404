import { createContext } from 'react'
import type { CurrentUserConfigQuery } from 'src/generated/graphql/types'
import type { UserInfos } from 'src/models/UserInfos'

type OrganizationConfig = {
  isTraaceAdmin: boolean
  organization: CurrentUserConfigQuery['currentUser']['organization']
  currentUser: UserInfos
  currentUserDefaultPerimeterScope: {
    contributingEntityIds: { id: string; name: string }[]
    organizationNodeIds: { id: string; name: string }[]
  }
}
export const OrganizationConfigContext = createContext<OrganizationConfig | undefined>(undefined)
