import { Language } from 'src/generated/graphql/types'
import type { I18nKey } from 'src/generated/i18n-types'
import { useTypedTranslation } from 'src/common/utils'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { type FlagComponent, GB } from 'country-flag-icons/react/3x2'
import { FR } from 'country-flag-icons/react/3x2'
import { DE } from 'country-flag-icons/react/3x2'
import { ES } from 'country-flag-icons/react/3x2'

const LANGUAGE_LABEL: Record<Language, I18nKey> = {
  [Language.En]: 'languages.en',
  [Language.Fr]: 'languages.fr',
  [Language.De]: 'languages.de',
  [Language.Es]: 'languages.es'
}

const LANGUAGE_FLAG: Record<Language, FlagComponent> = {
  [Language.En]: GB,
  [Language.Fr]: FR,
  [Language.De]: DE,
  [Language.Es]: ES
}

export function useLanguages() {
  const { t } = useTypedTranslation()
  const isSpanishReleased = useOverridableFlag(UnleashFlag.ReleaseSpanishLanguage)
  return {
    languages: Object.values(Language)
      .filter(language => {
        if (isSpanishReleased) return true
        return language !== Language.Es
      })
      .map(language => ({
        label: t(LANGUAGE_LABEL[language]),
        value: language,
        Flag: LANGUAGE_FLAG[language]
      }))
  }
}
