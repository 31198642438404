import { type CurrentOrganizationEmissionsItemsCategoriesQuery } from 'src/generated/graphql/types'
import { useCallback, useMemo } from 'react'
import { useTypedTranslation } from 'src/common/utils'
import { useCategories } from 'src/common/hooks/useCategories'
import orderBy from 'lodash/fp/orderBy'
import isEmpty from 'lodash/fp/isEmpty'

export type CategoryType =
  CurrentOrganizationEmissionsItemsCategoriesQuery['organizationEmissionsItemsCategories'][number]

export type CategoryTreeSelectNodeType = CategoryType & {
  key: string
  isLeaf: boolean
  value: string
  selectable: boolean
  localeName: string
  type: 'category'
  children?: CategoryTreeSelectNodeType[]
}

type CategoryTreeProps = {
  allowAllLevelsSelection?: boolean
  displayRoot?: boolean
  metricId?: string
}

const sortCategories = orderBy<CategoryTreeSelectNodeType>(['localeName'])(['asc'])

export function useCategoriesTree({
  allowAllLevelsSelection = false,
  displayRoot = false,
  metricId
}: CategoryTreeProps = {}) {
  const { getTranslatedValue } = useTypedTranslation()
  const { data, loading, refetch, error, getCategory } = useCategories({ metricId })

  const getChildrenOf: (category: CategoryType) => CategoryTreeSelectNodeType[] = useCallback(
    (category: CategoryType) => {
      return (
        data?.organizationEmissionsItemsCategories
          .filter(c => c.parent?.id === category.id)
          .map(c => {
            const children = getChildrenOf(c)
            const isLeaf = isEmpty(children)
            return {
              ...c,
              value: c.id,
              key: c.id,
              type: 'category',
              localeName: getTranslatedValue(c.name).value ?? '',
              isLeaf,
              selectable: isLeaf || allowAllLevelsSelection,
              children: sortCategories(children)
            }
          }) ?? []
      )
    },
    [data?.organizationEmissionsItemsCategories]
  )

  const treeNode = useMemo(() => {
    const rootCategory = data?.organizationEmissionsItemsCategories.find(category => !category.parent)
    if (!rootCategory) return []
    const children = getChildrenOf(rootCategory)
    return [
      {
        ...rootCategory,
        key: rootCategory.id,
        value: rootCategory.id,
        localeName: getTranslatedValue(rootCategory.name).value ?? '',
        isLeaf: false,
        selectable: false,
        type: 'category',
        children: sortCategories(children)
      }
    ]
  }, [data?.organizationEmissionsItemsCategories]) satisfies CategoryTreeSelectNodeType[]

  const firstLevelCategoryIds = useMemo(() => treeNode[0]?.children.map(child => child.id), [treeNode])

  const rootCategory = useMemo(
    () => data?.organizationEmissionsItemsCategories.find(category => !category.parent),
    [data]
  )

  return {
    treeNode: displayRoot ? treeNode : (treeNode[0]?.children ?? []),
    loading,
    getCategory,
    refetch,
    error,
    firstLevelCategoryIds,
    rootCategory,
    allCategories: data?.organizationEmissionsItemsCategories
  }
}
