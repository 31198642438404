import { type TOptions } from 'i18next'
import { useTranslation as __useTranslation } from 'react-i18next'
import type { I18nKey } from 'src/generated/i18n-types'
import { type FieldTranslation, Language } from 'src/generated/graphql/types'
import without from 'lodash/fp/without'
import isEmpty from 'lodash/fp/isEmpty'
import { useSortedLanguages } from 'src/provider/OrganizationSortedLanguageProvider'
import { useOverrideLanguage } from 'src/app/languages/overrideLanguage/useOverrideLanguage'

const i18nToLanguage: { [key: string]: Language } = {
  en: Language.En,
  fr: Language.Fr,
  de: Language.De,
  es: Language.Es
}

export function getLanguage(language: string): Language {
  return i18nToLanguage[language] ?? Language.En
}

const getOrderedLanguages = (currentLanguage: string, orderedLanguages: Language[]) => {
  return [currentLanguage, ...without([currentLanguage])(orderedLanguages)]
}

export type Translation = (key: I18nKey, options?: TOptions<{ [p: string]: any }>) => string

export function useTypedTranslation() {
  const { t: __t, i18n } = __useTranslation()
  const { overridedLanguage } = useOverrideLanguage()
  const t: Translation = (key: I18nKey, options?: TOptions<{ [key: string]: any }>) => __t(key, options || {})
  const { sortedLanguageCodes } = useSortedLanguages()

  const filterFieldTranslationsToAvailableLanguages = (fields: FieldTranslation[] | undefined | null) => {
    return fields?.filter(field => sortedLanguageCodes.includes(field.language)) ?? []
  }

  const getTranslatedValue = (
    fields: FieldTranslation[] | undefined | null
  ): { value: string | undefined; isCurrentLanguage: boolean } => {
    const currentLanguage = overridedLanguage ?? i18n.language
    for (const language of getOrderedLanguages(currentLanguage, sortedLanguageCodes)) {
      const value = fields?.find(field => field.language === getLanguage(language))?.value
      if (value) return { value, isCurrentLanguage: language === currentLanguage }
    }
    return { value: undefined, isCurrentLanguage: false }
  }

  const getTranslatedValueForLanguage = (fields: FieldTranslation[] | undefined | null, lang: Language): string => {
    return fields?.find(field => field.language === lang)?.value ?? ''
  }

  const isEmptyTranslation = (fields: FieldTranslation[] | undefined | null): boolean => {
    return fields?.every(field => isEmpty(field.value)) ?? true
  }

  const getFieldTranslationInputOf = (value: string): FieldTranslation => {
    return { language: getLanguage(i18n.language), value }
  }

  const extractFieldTranslationInput = (values: FieldTranslation[]) =>
    values.map(({ language, value }) => ({ language, value }))

  return {
    t,
    i18n,
    getTranslatedValue,
    getFieldTranslationInputOf,
    isEmptyTranslation,
    extractFieldTranslationInput,
    filterFieldTranslationsToAvailableLanguages,
    getTranslatedValueForLanguage
  }
}
