import find from 'lodash/fp/find'
import last from 'lodash/fp/last'
import { useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router'
import { type GetProp, Menu } from 'antd'

type NavigationStateProps = {
  upMenuItems: GetProp<typeof Menu, 'items'>
  downMenuItems: GetProp<typeof Menu, 'items'>
  setOpenKeys: (keys: string[]) => void
}

type KeyMatch = {
  key: string
  isMatching: boolean
  parent?: string
}

function buildKeysArray(params: { pathname: string; menu: GetProp<typeof Menu, 'items'> }): KeyMatch[] {
  let keys: KeyMatch[] = []
  const { pathname, menu } = params
  for (const menuItem of menu) {
    if (!menuItem) continue
    if ('children' in menuItem) {
      keys = [...keys, ...buildKeysArray({ pathname, menu: menuItem.children as GetProp<typeof Menu, 'items'> })]
    } else {
      const rawKey = menuItem.key as string
      const splittedKeys = rawKey.split(' ')
      const isSubMenu = splittedKeys.length > 1
      const keyPath = isSubMenu ? last(splittedKeys)! : rawKey
      const isMatching = !!matchPath(keyPath, pathname)
      keys = [...keys, { key: rawKey, isMatching, parent: isSubMenu ? splittedKeys[0] : undefined }]
    }
  }
  return keys
}

export function useNavigationState({ upMenuItems, downMenuItems, setOpenKeys }: NavigationStateProps) {
  const { pathname } = useLocation()
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const menu = [...upMenuItems, ...downMenuItems].filter(item => item && item.type !== 'divider')
  const menuKeys = buildKeysArray({ pathname, menu })
  useEffect(() => {
    const routeMatch = find<KeyMatch>({ isMatching: true })(menuKeys)
    if (routeMatch) {
      setSelectedKeys([routeMatch.key])
      setOpenKeys(routeMatch.parent ? [routeMatch.parent] : [])
    }
  }, [pathname, JSON.stringify(menuKeys)])

  return {
    selectedKeys,
    setSelectedKeys
  }
}
