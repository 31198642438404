import type { ItemType } from 'antd/es/menu/interface'
import { AgendaBookmarkIcon, ArrowOppositeDirectionIcon, LogoutIcon } from 'src/icons'
import { LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { useTypedTranslation } from 'src/common/utils'
import { useAvailableOrganizationsQuery } from 'src/generated/graphql/types'
import { useImpersonateEmail } from 'src/common/hooks/useImpersonateEmail'
import { useApolloClient } from '@apollo/client'
import { useNavigate } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'
import { useOrganizationConfig } from 'src/provider'
import { useOrganizationNavigate } from 'src/navigation/useOrganizationNavigate'

export function useUserMenu({ withProfilePage = false }: Readonly<{ withProfilePage?: boolean }> = {}) {
  const { t } = useTypedTranslation()
  const { currentUser } = useOrganizationConfig()
  const { data } = useAvailableOrganizationsQuery()
  const { isImpersonateUser, setImpersonateEmail } = useImpersonateEmail()
  const client = useApolloClient()
  const navigate = useNavigate()
  const orgNavigate = useOrganizationNavigate()
  const { logout } = useAuth0()

  const dropdownItems: ItemType[] = []

  if (withProfilePage) {
    dropdownItems.push({
      key: '/settings/team/users/*',
      icon: <UserOutlined />,
      label: t('user_details.title'),
      onClick: () => orgNavigate(`settings/user/${currentUser.authUser.id}`)
    })
  }

  if (data && data.listAvailableOrganizations.length > 1) {
    dropdownItems.push({
      key: 'swap',
      icon: <ArrowOppositeDirectionIcon />,
      label: t('menu.switch'),
      onClick: () => navigate('/')
    })
  }

  dropdownItems.push({
    key: 'help',
    icon: <AgendaBookmarkIcon />,
    label: t('menu.privacy'),
    onClick: () => window.open('/policies/app/privacy', '_blank', 'rel=noreferrer')
  })

  if (isImpersonateUser) {
    dropdownItems.push({
      key: 'impersonate',
      icon: <LogoutOutlined />,
      label: t('menu.unimpersonate'),
      onClick: ({ domEvent }) => {
        domEvent.preventDefault()
        setImpersonateEmail(undefined)
        client.resetStore()
        navigate('/')
      }
    })
  }
  dropdownItems.push({
    key: 'logout',
    icon: <LogoutIcon className="text-error-foreground" />,
    label: <span className="text-error-foreground">{t('login.logout_button_title')}</span>,
    onClick: async () => {
      await logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      })
    }
  })
  return dropdownItems
}
