import { message } from 'antd'
import React from 'react'
import { useTypedTranslation } from '../common/utils'
import CenteredLoader from '../common/utils/CenteredLoader'
import {
  useCurrentOrganizationEmissionsItemsCategories,
  type EmissionsItemsCategory
} from 'src/modules/settings/dataManagement/categories/_hooks/useOrganizationEmissionsItemsCategories'

interface CategoryProviderProps {
  readonly children: React.ReactNode
  readonly hideLoader?: boolean
}

interface CategoriesContextInterface {
  allEmissionItemsCategories: EmissionsItemsCategory[]
}

export const CategoriesContext = React.createContext<CategoriesContextInterface>({
  allEmissionItemsCategories: []
})

export function CategoriesProvider({ children, hideLoader }: CategoryProviderProps) {
  const { data, loading, error } = useCurrentOrganizationEmissionsItemsCategories()
  const { t } = useTypedTranslation()

  if (loading) {
    return hideLoader ? null : <CenteredLoader fullHeight={false} />
  }

  if (error || !data) {
    message.error(t('common.error_message'))
    console.error(error)
    return null
  }

  return (
    <CategoriesContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        allEmissionItemsCategories: data.emissionItemsCategories
      }}
    >
      {children}
    </CategoriesContext.Provider>
  )
}
