import { Tooltip } from 'antd'
import { highlightText, useTypedTranslation } from 'src/common/utils'
import type { FieldTranslation } from 'src/generated/graphql/types'
import classNames from 'classnames'

type TranslatedValueProps = Readonly<{
  fields: FieldTranslation[] | null | undefined
  className?: string
  rawValue?: boolean
  highlightWord?: string
}>

export function TranslatedValue({ fields, className, rawValue = false, highlightWord }: TranslatedValueProps) {
  const { t, getTranslatedValue } = useTypedTranslation()
  const { value, isCurrentLanguage } = getTranslatedValue(fields)
  if (!value) return null
  if (isCurrentLanguage || rawValue)
    return <span className={className}>{highlightWord ? highlightText(value, highlightWord) : value}</span>
  return (
    <span className={classNames('inline-flex gap-1 italic items-center')}>
      <span className={className}>{highlightWord ? highlightText(value, highlightWord) : value}</span>
      <Tooltip title={t('common.translation_required')}>
        {/* z-50 allows tootlip to be displayed even in the navigation menu */}
        <span className={'z-50 text-greengrey-30'}>*</span>
      </Tooltip>
    </span>
  )
}
