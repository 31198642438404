import type { Metric } from 'src/type'
import { useMetrics } from 'src/common/hooks'
import { TranslatedValue } from 'src/common/designSystem/TranslatedValue'
import React from 'react'
import { useTheme } from 'src/app/theme/useTheme'

type MetricLabelProps = Readonly<{ metric: Pick<Metric, 'organization' | 'label' | 'id'> }>

export function MetricLabel({ metric }: MetricLabelProps) {
  const { getMetricUnitLabel } = useMetrics()
  const { images } = useTheme()

  return (
    <div className="flex gap-1 items-center">
      {!metric.organization?.id && <img alt="" src={images.compactLogoUrl} className="w-4 h-4" />}
      <TranslatedValue fields={metric.label} />
      <span>({getMetricUnitLabel(metric.id)})</span>
    </div>
  )
}
