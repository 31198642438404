import { traaceTheme } from 'src/app/theme/traace'
import { tennaxiaTheme } from 'src/app/theme/tennaxia'
import { lightTheme } from 'src/app/theme/light'
import { OrganizationConfigContext } from 'src/provider'
import { Theme } from 'src/generated/graphql/types'
import { useContext } from 'react'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'

export function useTheme() {
  const newThemeEnabled = useOverridableFlag(UnleashFlag.NewBranding)
  const context = useContext(OrganizationConfigContext)

  if (newThemeEnabled) return lightTheme
  return context?.organization.theme === Theme.Tennaxia ? tennaxiaTheme : traaceTheme
}
