import type { ThemeColorsType } from 'src/app/theme/type'
import type { ThemeConfig } from 'antd'

export const legacyAppTheme = (colors: ThemeColorsType): ThemeConfig => ({
  components: {
    Layout: { headerBg: colors.sidebarBackground, siderBg: colors.sidebarBackground },
    Menu: {
      itemBg: colors.darkgreenBase,
      groupTitleColor: colors.sidebarForeground,
      subMenuItemBg: colors.primary,
      itemSelectedBg: colors.primary,
      itemActiveBg: colors.primary,
      itemColor: colors.greengrey10,
      itemHoverColor: colors.greengrey2,
      itemSelectedColor: colors.greengrey2,
      subMenuItemSelectedColor: colors.white,
      popupBg: colors.sidebarBackground
    },
    DatePicker: {
      controlHeight: 40
    },
    Table: {
      colorFillAlter: colors.white,
      colorLink: colors.darkgreenLighter1,
      colorLinkHover: colors.darkgreenLighter1,
      colorLinkActive: colors.darkgreenLighter1,
      headerBg: colors.white,
      headerSortActiveBg: colors.white
    },
    Drawer: {
      fontWeightStrong: 400
    },
    Button: {
      borderRadius: 0,
      borderRadiusSM: 0,
      controlOutlineWidth: 0,
      controlHeight: 40
    },
    Pagination: {
      controlHeight: 40
    },
    Tag: {
      borderRadiusSM: 100
    },
    Input: { controlHeight: 40, hoverBorderColor: colors.border },
    InputNumber: { controlHeight: 40 },
    TreeSelect: { controlItemBgActive: colors.greengrey10 },
    Dropdown: { controlHeight: 40, controlItemBgActive: colors.greengrey10 },
    Select: { controlHeight: 40, controlItemBgActive: colors.greengrey10 },
    Progress: { colorInfo: colors.primary, colorSuccess: colors.primary }
  },
  token: {
    colorPrimary: colors.darkgreenLighter1,
    colorPrimaryBg: colors.backgroundLight,
    colorPrimaryBgHover: colors.backgroundLight,
    colorError: colors.errorForeground,
    colorErrorBg: `${colors.errorForeground}16`,
    colorErrorBorder: colors.errorForeground,
    colorWarning: colors.warningForeground,
    colorWarningBg: `${colors.warningForeground}16`,
    colorWarningBorder: colors.warningForeground,
    colorInfo: colors.infoForeground,
    colorInfoBg: `${colors.infoForeground}16`,
    colorInfoBorder: colors.infoForeground,
    colorSuccess: colors.successForeground,
    colorSuccessBg: `${colors.successForeground}16`,
    colorSuccessBorder: colors.successForeground,
    colorLink: colors.darkgreenLighter1,
    colorLinkHover: colors.darkgreenLighter1,
    colorLinkActive: colors.darkgreenLighter1,
    fontFamily: 'Inter, sans-serif',
    motionDurationFast: '0.075s',
    motionDurationMid: '0.15s',
    motionDurationSlow: '0.225s',
    borderRadius: 2
  }
})

export const newAppTheme = (colors: ThemeColorsType): ThemeConfig => ({
  components: {
    Layout: { headerBg: colors.sidebarBackground, siderBg: colors.sidebarBackground },
    Menu: {
      itemBg: colors.sidebarBackground,
      groupTitleColor: colors.sidebarForeground,
      subMenuItemBg: colors.sidebarBackground,
      itemSelectedBg: colors.sidebarAccent,
      itemActiveBg: colors.sidebarAccent,
      itemHoverBg: colors.sidebarAccent,
      itemColor: colors.sidebarForeground,
      itemHoverColor: colors.sidebarAccentForeground,
      itemSelectedColor: colors.sidebarAccentForeground,
      subMenuItemSelectedColor: colors.sidebarAccentForeground,
      popupBg: colors.sidebarBackground
    },
    Table: {
      colorFillAlter: colors.white,
      colorLink: colors.primary,
      colorLinkHover: colors.primary,
      colorLinkActive: colors.primary,
      headerBg: colors.white,
      headerSortActiveBg: colors.white,
      rowSelectedBg: colors.accent,
      rowSelectedHoverBg: colors.accent
    },
    Input: {
      activeShadow: undefined,
      hoverBorderColor: colors.border
    },
    InputNumber: {
      activeShadow: undefined
    },
    DatePicker: {
      activeShadow: undefined
    },
    Select: {
      optionSelectedBg: colors.accent,
      optionActiveBg: colors.accent,
      hoverBorderColor: colors.border
    },
    Button: {
      controlOutlineWidth: 0,
      contentFontSizeSM: 12,
      contentFontSize: 14,
      contentFontSizeLG: 14,
      paddingInlineSM: 4,
      paddingInline: 8,
      paddingInlineLG: 8,
      defaultShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
      primaryShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
      dangerShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
      defaultHoverColor: colors.foreground,
      defaultHoverBorderColor: colors.border,
      defaultHoverBg: colors.accent,
      textHoverBg: colors.accent
    },
    Tag: {
      borderRadiusSM: 100
    },
    Tree: { nodeHoverBg: colors.accent, nodeSelectedBg: colors.accent },
    TreeSelect: { nodeHoverBg: colors.accent, nodeSelectedBg: colors.accent },
    Dropdown: { controlItemBgActive: colors.accent },
    Progress: { defaultColor: colors.primary, colorSuccess: colors.successForeground },
    Tabs: {
      itemColor: colors.mutedForeground
    },
    Card: {
      bodyPadding: 12,
      headerPadding: 12
    },
    Segmented: {
      trackBg: colors.sidebarAccent,
      borderRadiusSM: 6 // Used for the inner radius, need to be 6 to have something ok with the outer 8px radius
    }
  },
  token: {
    colorText: colors.foreground,
    colorTextDisabled: colors.mutedForeground,
    colorBgContainerDisabled: colors.muted,
    colorBorder: colors.border,
    colorSplit: colors.border,
    colorBorderSecondary: colors.border,
    controlItemBgHover: colors.accent,
    colorPrimary: colors.primary,
    colorPrimaryHover: `${colors.primary}cc`,
    colorError: colors.errorForeground,
    colorErrorBg: colors.error,
    colorErrorBorder: colors.errorForeground,
    colorWarning: colors.warningForeground,
    colorWarningBg: colors.warning,
    colorWarningBorder: colors.warningForeground,
    colorInfo: colors.infoForeground,
    colorInfoBg: colors.info,
    colorInfoBorder: colors.infoForeground,
    colorSuccess: colors.successForeground,
    colorSuccessBg: colors.success,
    colorSuccessBorder: colors.successForeground,
    colorLink: colors.primary,
    colorLinkHover: colors.primary,
    colorLinkActive: colors.primary,
    fontFamily: 'Inter, sans-serif',
    motionDurationFast: '0.075s',
    motionDurationMid: '0.15s',
    motionDurationSlow: '0.225s',
    boxShadowSecondary:
      '0 6px 2px 0 rgba(0, 0, 0, 0.00), 0 4px 1px 0 rgba(0, 0, 0, 0.01), 0 2px 1px 0 rgba(0, 0, 0, 0.03), 0 1px 1px 0 rgba(0, 0, 0, 0.04)',
    colorBgLayout: colors.background,
    colorBgContainer: colors.white,
    borderRadiusSM: 4,
    borderRadius: 8,
    borderRadiusLG: 8,
    paddingLG: 20
  }
})
