import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { REDUCE_MENU, ROOT } from 'src/navigation/routes'
import { usePermissions } from 'src/navigation/_hooks'
import type { ItemType } from 'antd/es/menu/interface'
import { FileCopiesIcon, PositionIcon, TodoIcon } from 'src/icons'

type useReduceMenuProps = {
  isReduceModuleEnabled: boolean
}

export const useReduceMenu = ({ isReduceModuleEnabled }: useReduceMenuProps): ItemType | null => {
  const { t } = useTypedTranslation()
  const { canReadAction, canReadTrajectoryAndForecast, canReadActionPlan } = usePermissions()
  const reduceDisplayForced = useOverridableFlag(UnleashFlag.ForceReduceDisplay)

  if (!reduceDisplayForced && !isReduceModuleEnabled) return null

  const children: ItemType[] = []

  if (canReadTrajectoryAndForecast) {
    children.push({
      key: `${REDUCE_MENU} ${ROOT}/trajectory/*`,
      icon: <PositionIcon />,
      label: <OrganizationLink to="/trajectory">{t('menu.trajectory')}</OrganizationLink>
    })
  }

  if (canReadActionPlan) {
    children.push({
      key: `${REDUCE_MENU} ${ROOT}/actionPlans/*`,
      icon: <TodoIcon />,
      label: <OrganizationLink to="/actionPlans">{t('menu.roadmap')}</OrganizationLink>
    })
  }

  if (canReadAction) {
    children.push({
      key: `${REDUCE_MENU} ${ROOT}/actionTemplates/*`,
      icon: <FileCopiesIcon />,
      label: <OrganizationLink to="/actionTemplates">{t('menu.action_templates')}</OrganizationLink>
    })
  }

  if (children.length === 0) return null
  return {
    key: REDUCE_MENU,
    type: 'group',
    label: t('menu.drive'),
    children
  }
}
