export const ROOT = '/o/:organizationId'
export const DATA_COLLECTION_ROUTE = `${ROOT}/dataCollection`
export const CONNECT_ROUTE = `${ROOT}/connect`
export const IMPORTS_ROUTE = `${ROOT}/imports_v2`
export const MANUAL_ENTRIES_ROUTE = `${ROOT}/manualEntries`
export const MAPPINGS_ROUTE = `${ROOT}/mappings`

export const CAMPAIGNS_ROUTE = `${ROOT}/campaigns`
export const QUESTIONNAIRES_ROUTE = `${ROOT}/questionnaires`

export const ACTION_TEMPLATES_ROUTE = `${ROOT}/actionTemplates`
export const TRAJECTORY_ROUTE = `${ROOT}/trajectory`
export const ACTION_PLANS_ROUTE = `${ROOT}/actionPlans`
export const USER_ROUTE = `${ROOT}/settings/user/:authUserId`
export const AUDIT_ROUTE = `${ROOT}/settings/audit`
export const SUPPLY_CHAIN_ROUTE = `${ROOT}/supplyChain/:tabKey`

export const ORGANIZATION_ROUTE_NEXT = `${ROOT}/settings/organization/*`
export const DATA_MANAGEMENT_ROUTE_NEXT = `${ROOT}/settings/data-management/*`
export const TEAM_ROUTE_NEXT = `${ROOT}/settings/team/*`

export const MATERIALITY_ASSESSMENT_ROUTE = `${ROOT}/reporting/materialityAssessment`
export const KNOWLEDGE_BASE_ROUTE = `${ROOT}/reporting/kb`

export const DASHBOARDS_ROUTE = `${ROOT}/analyses/dashboards`
export const EXPORTS_ROUTE = `${ROOT}/analyses/exports`

export const MEASURE_MENU = 'MEASURE_MENU'
export const COLLECT_MENU = 'COLLECT_MENU'
export const MY_TASKS_MENU = 'MY_TASKS_MENU'
export const REDUCE_MENU = 'REDUCE_MENU'
export const SETTINGS_MENU = 'SETTINGS_MENU'
export const ADMIN_MENU = 'ADMIN_MENU'
export const ANALYZE_MENU = 'ANALYZE_MENU'
export const REPORTING_MENU = 'REPORTING_MENU'
export const ADDONS_MENU = 'ADDONS_MENU'
export const CUSTOM_LINK_MENU = 'CUSTOM_LINK_MENU'
export const CAMPAIGN_MENU = 'CAMPAIGN_MENU'
export const IMPORTS_MENU = 'IMPORTS_MENU'
