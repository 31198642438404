import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { Route, Routes, useParams } from 'react-router'
import { LazyLoadFallback } from 'src/app/LazyLoadFallback'
import { Main } from 'src/app/organization/Main'
import { useAuthentifiedApolloClient } from 'src/common/apollo/apolloClient'
import { ConfigProvider } from 'src/provider'
import { UnleashProvider } from 'src/services/unleash'
import { FlowStatusChecker } from 'src/common/flows/FlowStatusChecker'
import { useImpersonateEmail } from 'src/common/hooks/useImpersonateEmail'
import { AuthAntConfigProvider } from 'src/app/theme/AuthAntConfigProvider'
import { useTheme } from 'src/app/theme/useTheme'

const OnBoarding = React.lazy(() => import('src/app/organization/Onboarding'))

export function Organization() {
  const { organizationId } = useParams<'organizationId'>()
  const { impersonateEmail } = useImpersonateEmail()
  const client = useAuthentifiedApolloClient({ organizationId, impersonateEmail })
  const theme = useTheme()

  return (
    <div className={`theme-${theme.name.toLowerCase()}`}>
      <ApolloProvider client={client}>
        <ConfigProvider>
          <AuthAntConfigProvider>
            <UnleashProvider>
              <FlowStatusChecker />
              <Routes>
                <Route
                  path="onboarding"
                  element={
                    <LazyLoadFallback>
                      <OnBoarding />
                    </LazyLoadFallback>
                  }
                />
                <Route path="*" element={<Main />} />
              </Routes>
            </UnleashProvider>
          </AuthAntConfigProvider>
        </ConfigProvider>
      </ApolloProvider>
    </div>
  )
}
